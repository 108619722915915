import { api } from 'shared/api/axios';

type GetCollectionsReq = {
  collectionType: string;
};

const getCollections = ({ collectionType }: GetCollectionsReq) => {
  return api({
    url: `/engagement/collection/${collectionType}`,
    method: 'GET',
  });
};
const getUsdRate = () => {
  return api({
    url: `/rate/wbtc`,
    method: 'GET',
  });
};

export const ringApi = {
  getCollections,
  getUsdRate,
};
