import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { routes } from 'app/providers/router/routes';
import { coupleSelector } from 'entities/couple';
import { modelSelector } from 'entities/models';
import { ModelsContainer, WalletKeyContainer } from 'features/connection/containers';
import { useShallowSelector } from 'shared/hooks';
import { EngagementType, EngagementTypePath } from 'shared/types';
import { ArrowIcon } from 'shared/ui/components';

export const Models = () => {
  const navigate = useNavigate();

  const { engagementType } = useShallowSelector(coupleSelector.getCouple);
  const { userModels } = useShallowSelector(modelSelector.getModel);

  const handleBack = () => {
    navigate(routes.connection.path);
  };

  useEffect(() => {
    if (engagementType === EngagementType.Person) {
      navigate(EngagementTypePath[engagementType]);
    }
  }, [engagementType, navigate]);

  return (
    <Stack maxWidth={550} width="100%" alignItems="center" mt={2.5}>
      <IconButton size="small" color="info" onClick={handleBack} sx={{ mr: 'auto' }}>
        <ArrowIcon />
      </IconButton>
      <Typography
        variant="h2"
        className="white center md"
        flex={1}
        mt={{ xs: 2, sm: 4 }}
        width={{ xs: 279, sm: 'unset' }}
      >
        Activate Commitment ring
      </Typography>

      {!userModels.length ? <WalletKeyContainer /> : <ModelsContainer />}
    </Stack>
  );
};
