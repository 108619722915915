import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { modalActions, ModalType } from 'entities/modal';
import { User, UserRole, userSelector, UserStatus } from 'entities/user';
import { weddingActions, weddingSelector } from 'entities/wedding';
import { GodWitness, witnessActions, witnessSelector } from 'entities/witness';
import { times } from 'lodash';
import { uiSelector } from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { EngagementType, Nullable, SexType } from 'shared/types';
import { Slider, Typography } from 'shared/ui/components';
import { checkRequestStatusEquality } from 'shared/utils';
import { useTranslation } from 'react-i18next';

export const SelectWitness = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const uiState = useShallowSelector(uiSelector.getUI);
  const { members, type } = useShallowSelector(weddingSelector.getWedding);
  const gods = useShallowSelector(witnessSelector.getGodWitnesses);
  const { id: userId, sex: userSex } = useShallowSelector(userSelector.getUser);
  const isUserMan = userSex === SexType.man;

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [clickSlideIndex, setClickSlideIndex] = useState<Nullable<number>>(null);
  const [userWitnessId, setUserWitnessId] = useState<Nullable<string>>(null);

  const currentUser = members.find(({ user: { id } }) => id === userId);
  const partner = members.find(({ user: { id } }) => id !== userId);
  const partnerWitnessId = partner?.godWitness?.id || '';
  const isUsersChoseSameGod = partnerWitnessId && userWitnessId ? partnerWitnessId === userWitnessId : false;

  const currentUserDisplayData = {
    status: UserStatus.You,
    firstName: currentUser?.user.firstName || '',
    lastName: currentUser?.user.lastName || '',
    avatar: currentUser?.user.profileImage,
  };

  const partnerDisplayData = {
    status: partner?.user.isOnline ? UserStatus.Online : UserStatus.Offline,
    firstName: partner?.user.firstName || '',
    lastName: partner?.user.lastName || '',
    avatar: partner?.user.profileImage,
  };

  const firstUserDisplayData = !isUserMan ? currentUserDisplayData : partnerDisplayData;
  const secondUserDisplayData = isUserMan ? currentUserDisplayData : partnerDisplayData;

  // TODO: Check why loading state not working
  const isSelectGodWitnessLoading = checkRequestStatusEquality(uiState[weddingActions.selectGodWitness?.type]);
  const isConfirmGodLoading = checkRequestStatusEquality(uiState[weddingActions.confirmGodWitness?.type]);

  const handleConfirmGodClick = () => {
    dispatch(weddingActions.confirmGodWitness());
  };
  const handleOpenInviteModal = () => {
    dispatch(
      modalActions.openModal({
        type: ModalType.InviteWitness,
      }),
    );
  };

  const selectGod = useCallback(
    (index: number) => {
      setUserWitnessId(gods[index]?.id);
      dispatch(
        weddingActions.selectGodWitness({
          godWitnessId: gods[index]?.id,
        }),
      );
    },
    [gods, dispatch],
  );
  const handleSlideMove = (splide: Splide, index: number) => {
    setActiveSlideIndex(index);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSlideClick = (splide: Splide, event: any) => {
    setClickSlideIndex(event.index);
  };

  useEffect(() => {
    dispatch(witnessActions.getGodWitnesses());
    return () => {
      dispatch(modalActions.closeModal());
    };
  }, [dispatch]);

  useEffect(() => {
    setUserWitnessId((prev) => (prev === null ? currentUser?.godWitness?.id || null : prev));
  }, [currentUser?.godWitness?.id]);

  useEffect(() => {
    if (clickSlideIndex === activeSlideIndex) {
      selectGod(clickSlideIndex);
    }
  }, [activeSlideIndex, clickSlideIndex, selectGod]);

  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item>
        <User {...firstUserDisplayData} role={UserRole.Female} />
      </Grid>
      <Grid
        item
        container
        order={{ xs: 3, lg: 2 }}
        xs={12}
        lg="auto"
        justifyContent="center"
        direction="column"
        spacing={{ xs: 2, md: 5 }}
      >
        <Grid item mt={{ xs: 0, lg: 1 }}>
          <Typography variant="h3" className="center">
            {t('witness_p1')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          mx="auto"
          justifyContent="center"
          alignItems="center"
          direction={{ xs: 'column', md: 'row' }}
          gap={1.25}
        >
          <Grid item>
            <Typography className="center">{t('witness_divine')}</Typography>
          </Grid>
          <Grid item>
            <Button className="gray" onClick={handleOpenInviteModal}>
              + {t('witness_invite')}
            </Button>
          </Grid>
        </Grid>
        <Grid item mx="auto">
          <Slider onMoved={handleSlideMove} onClick={handleSlideClick}>
            {gods.length
              ? gods.map(({ id, imageUrl }) => (
                  <SplideSlide key={id}>
                    <GodWitness
                      withChecks
                      activeFirstCheck={userWitnessId === id}
                      activeSecondCheck={
                        partnerWitnessId === id || (type === EngagementType.Model && userWitnessId === id)
                      }
                      activeBorder={userWitnessId === id}
                      img={imageUrl}
                    />
                  </SplideSlide>
                ))
              : times(3, (index) => (
                  <SplideSlide key={index}>
                    <GodWitness withChecks img="" />
                  </SplideSlide>
                ))}
          </Slider>
        </Grid>
        <Grid item mx="auto" mt={{ xs: 9, md: 3 }}>
          <Button
            variant="contained"
            sx={{ minWidth: 189 }}
            disabled={
              !userWitnessId ||
              (!isUsersChoseSameGod && type === EngagementType.Person) ||
              isConfirmGodLoading ||
              isSelectGodWitnessLoading
            }
            onClick={handleConfirmGodClick}
          >
            Proceed
          </Button>
        </Grid>
      </Grid>
      <Grid item order={{ xs: 2, lg: 3 }}>
        <User {...secondUserDisplayData} role={UserRole.Man} />
      </Grid>
    </Grid>
  );
};
