import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Link, Stack } from '@mui/material';
import BigNumber from 'bignumber.js';
import { ModelContacts } from 'entities/models/ui';
import { ringActions, ringSelector } from 'entities/ring';
import { RingWithUser } from 'entities/ring-with-user';
import { userActions, UserRole, userSelector, UserStatus } from 'entities/user';
import { weddingSelector } from 'entities/wedding';
import { PresentBlock } from 'features';
import { CertificateContainer } from 'features/certificate';
import { CertificateActionButtons } from 'features/certificate-action-buttons';
import { Footer } from 'pages/home/ui/Footer';
import { COLOR_WH } from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { EngagementType, SexType } from 'shared/types';
import { Typography } from 'shared/ui/components';
import { useTranslation } from 'react-i18next';

export const Certificate = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { members, certificate, witness, type } = useShallowSelector(weddingSelector.getWedding);
  const { collections } = useShallowSelector(ringSelector.getRing);
  const { sex: userSex, id: userId, referralCode } = useShallowSelector(userSelector.getUser);
  const isCurrentUserWitness = userId === witness?.user.id;
  const isCurrentUserMan = userSex === SexType.man;
  const isFirstMemberWoman = members[0]?.user.sex === SexType.woman;
  const firstMemberDisplayData = {
    status: UserStatus.Owns,
    firstName: members[0]?.user.firstName,
    lastName: members[0]?.user.lastName,
    avatar: members[0]?.user.profileImage,
    ringImg: members[1]?.token?.meta?.imageUrl || '',
    ringId: members[1]?.token?.externalTokenId,
    priceInUsd: parseFloat(new BigNumber(members[1]?.token?.wbtcAmountUsd || 0).toFixed(2, 0)),
    priceInToken: parseFloat(new BigNumber(members[1]?.token?.wbtcAmount || 0).toFixed(4, 0)),
    promoCode: referralCode,
    contscts: members[0]?.user.contscts,
  };

  const secondMemberDisplayData = {
    status: UserStatus.Owns,
    firstName: members[1]?.user.firstName,
    lastName: members[1]?.user.lastName,
    avatar: members[1]?.user.profileImage,
    ringImg: members[0]?.token?.meta?.imageUrl || '',
    ringId: members[0]?.token?.externalTokenId,
    priceInUsd: parseFloat(new BigNumber(members[0]?.token?.wbtcAmountUsd || 0).toFixed(2, 0)),
    priceInToken: parseFloat(new BigNumber(members[0]?.token?.wbtcAmount || 0).toFixed(4, 0)),
    promoCode: referralCode,
    contscts: members[1]?.user.contscts,
  };

  const femaleDisplayData = isFirstMemberWoman ? firstMemberDisplayData : secondMemberDisplayData;
  const manDisplayData = !isFirstMemberWoman ? firstMemberDisplayData : secondMemberDisplayData;
  const currentUserDisplayData = isCurrentUserMan ? manDisplayData : femaleDisplayData;
  const partnerDisplayData = isCurrentUserMan ? femaleDisplayData : manDisplayData;

  useEffect(() => {
    dispatch(ringActions.getCollections());
    dispatch(userActions.getUserInfo());
  }, [dispatch]);

  return (
    <>
      <Stack alignItems="center" mt={5} pb={{ xs: 1, md: 3.5 }} spacing={4}>
        {type === EngagementType.Model && (
          <Stack spacing={3} maxWidth={640} pb={{ xs: 2, md: 3 }}>
            <Typography variant="h2" className="center white" mb={2}>
              {t('certificate_Congrats')}
            </Typography>
            <Typography className="center gray">
              {t('certificate_d1')}
              <br />
              {t('certificate_d2')}
            </Typography>
            <ModelContacts
              imageUrl={partnerDisplayData.avatar}
              name={partnerDisplayData.firstName}
              contacts={partnerDisplayData.contscts}
            />
          </Stack>
        )}
        <CertificateContainer femaleProps={femaleDisplayData} manProps={manDisplayData} />

        <Typography maxWidth={740} className="center">
          {t('certificate_d3')}
        </Typography>

        <CertificateActionButtons type="certificate" />

        {!isCurrentUserWitness && (
          <Stack width="100%" maxWidth={800} spacing={4.25} alignItems="center">
            <RingWithUser
              ringProps={{ img: currentUserDisplayData.ringImg }}
              userProps={{
                ...currentUserDisplayData,
                role: isCurrentUserMan ? UserRole.Man : UserRole.Female,
                status: UserStatus.You,
                id: currentUserDisplayData.ringId,
              }}
              size="extra-large"
            />
            <Typography className="center" maxWidth={737}>
              {t('certificate_d4')}
            </Typography>
            <CertificateActionButtons type="ring" />
          </Stack>
        )}

        {/*<PresentBlock promoCode={currentUserDisplayData.promoCode} />*/}

        <Typography variant="h3" pt={{ xs: 1, md: 16 }}>
          FAQ
        </Typography>
        <Accordion sx={{ maxWidth: '90vw' }}>
          <AccordionSummary aria-controls="collections-content" id="collection-accordion">
            <Typography> {t('certificate_p2')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              {t('certificate_d5')}
            </Typography>
            {collections.map(({ id, name, scanUrl }) => (
              <Typography variant="body2" key={id} mt={1} textOverflow="ellipsis" overflow="hidden">
                <Typography variant="body2" component="span" className="capitalize">
                  {name}{' '}
                </Typography>
                -{' '}
                <Link href={scanUrl} color={COLOR_WH} target="_blank" rel="noreferrer">
                  {scanUrl}
                </Link>
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ maxWidth: '90vw' }}>
          <AccordionSummary aria-controls="collections-content" id="collection-accordion">
            <Typography>{t('certificate_p3')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
              {t('certificate_d6')}{' '}
              <Link href={certificate?.vaultContractUrl} color={COLOR_WH} target="_blank" rel="noreferrer">
                {certificate?.vaultContractUrl}
              </Link>{' '}
              {t('certificate_d6_1')}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Stack>
      <Footer />
    </>
  );
};
