import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'app/store';

import { NftModelState, SelectNftModelReq } from '../types';

export type ModelState = {
  userModels: NftModelState[];
};

export const nftModelInitialState: NftModelState = {
  id: null,
  createdAt: null,
  tokenId: null,
  isMinted: false,
  contacts: null,
  owner: null,
  meta: null,
};

export const initialState: ModelState = {
  userModels: [],
};

export const modelSelector = {
  getModel: (state: State): ModelState => state.model,
};

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    updateModel: (state, action: PayloadAction<Partial<ModelState>>) => ({
      ...state,
      ...action.payload,
    }),

    clearModelState: () => ({
      ...initialState,
    }),

    /** For saga */
    getUserNftModels(state, action: PayloadAction) {},
    selectNftModel(state, action: PayloadAction<SelectNftModelReq>) {},
  },
});

export const { reducer: modelReducer } = modelSlice;
export const { actions: modelActions } = modelSlice;
