import { userApi } from 'entities/user';
import { weddingActions } from 'entities/wedding';
import { weddingApi } from 'entities/wedding/api/ajax';
import { connectSocket, error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { camelize, logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

export function* getEngagementSaga({
  type,
  payload: { engagementType },
}: SagaPayload<typeof weddingActions.getEngagement>) {
  yield* put(request(type));
  try {
    const engagementsArr = yield* call(weddingApi.getEngagementData);
    const engagementId = engagementsArr[0].id;

    const data = yield* call(userApi.getWsToken, { engagementId });
    const { accessToken } = camelize(data);
    connectSocket(accessToken, engagementType);

    yield put(weddingActions.updateWedding({ id: engagementId }));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getEngagement', err);
  }
}

export default function* listener() {
  yield takeLatest(weddingActions.getEngagement.type, getEngagementSaga);
}
