import { CircularProgress, Stack } from '@mui/material';
import { COLOR_ACCENT } from 'shared/config';
import { BORDER_RADIUS_XXL } from 'shared/config/theme/common';

import { ModalType } from '../model';

export interface IMoonpayPayload {
  type: ModalType.Moonpay;
  data: { link: string };
}

export const MoonpayModal = ({ data: { link } }: { data: IMoonpayPayload['data'] }) => {
  return (
    <Stack
      width={555}
      height={{ xs: 680, md: 710 }}
      id="modalContent"
      sx={{
        borderRadius: BORDER_RADIUS_XXL,
        overflow: 'hidden',
        position: 'relative',
        iframe: {
          width: '100%',
          height: '100%',
          border: 'none',
        },
      }}
    >
      <CircularProgress
        size={123}
        sx={{
          color: COLOR_ACCENT,
          position: 'absolute',
          zIndex: -1,
          left: 'calc(50% - 61px)',
          top: 'calc(50% - 61px)',
        }}
      />
      <iframe id="moonpayIframe" src={link} title="MoonPay" />
    </Stack>
  );
};
