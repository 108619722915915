import { SvgIcon, SvgIconProps } from '@mui/material';

export const Discord = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon width="31" height="32" viewBox="0 0 31 32" fill="none" sx={sx} {...props}>
    <path
      d="M12.9142 13.5596C12.0497 13.5596 11.3672 14.3179 11.3672 15.2431C11.3672 16.1682 12.0649 16.9266 12.9142 16.9266C13.7787 16.9266 14.4612 16.1682 14.4612 15.2431C14.4764 14.3179 13.7787 13.5596 12.9142 13.5596ZM18.45 13.5596C17.5855 13.5596 16.903 14.3179 16.903 15.2431C16.903 16.1682 17.6007 16.9266 18.45 16.9266C19.3145 16.9266 19.997 16.1682 19.997 15.2431C19.997 14.3179 19.3145 13.5596 18.45 13.5596Z"
      fill="#7289DA"
    />
    <path
      d="M25.8212 0.833984H5.49784C3.78401 0.833984 2.38867 2.22932 2.38867 3.95832V24.4637C2.38867 26.1927 3.78401 27.588 5.49784 27.588H22.6968L21.893 24.7822L23.8343 26.587L25.6695 28.2857L28.9303 31.1673V3.95832C28.9303 2.22932 27.535 0.833984 25.8212 0.833984ZM19.9668 20.6417C19.9668 20.6417 19.4208 19.9895 18.9658 19.4132C20.9527 18.852 21.711 17.6083 21.711 17.6083C21.0892 18.0178 20.4977 18.306 19.9668 18.5032C19.2085 18.8217 18.4805 19.034 17.7677 19.1553C16.3117 19.4283 14.977 19.3525 13.8395 19.1402C12.975 18.9733 12.2318 18.7307 11.61 18.488C11.2612 18.3515 10.882 18.1847 10.5028 17.9723C10.4573 17.942 10.4118 17.9268 10.3663 17.8965C10.336 17.8813 10.3208 17.8662 10.3057 17.851C10.0327 17.6993 9.88101 17.5932 9.88101 17.5932C9.88101 17.5932 10.609 18.8065 12.5352 19.3828C12.0802 19.9592 11.519 20.6417 11.519 20.6417C8.16717 20.5355 6.89317 18.3363 6.89317 18.3363C6.89317 13.4527 9.07717 9.49415 9.07717 9.49415C11.2612 7.85615 13.339 7.90165 13.339 7.90165L13.4907 8.08365C10.7607 8.87232 9.50184 10.0705 9.50184 10.0705C9.50184 10.0705 9.83551 9.88849 10.3967 9.63065C12.0195 8.91782 13.3087 8.72065 13.8395 8.67515C13.9305 8.65998 14.0063 8.64482 14.0973 8.64482C15.0225 8.52349 16.069 8.49315 17.161 8.61449C18.6018 8.78132 20.1488 9.20599 21.7262 10.0705C21.7262 10.0705 20.528 8.93298 17.9497 8.14432L18.162 7.90165C18.162 7.90165 20.2398 7.85615 22.4238 9.49415C22.4238 9.49415 24.6078 13.4527 24.6078 18.3363C24.6078 18.3363 23.3187 20.5355 19.9668 20.6417V20.6417Z"
      fill="#7289DA"
    />
  </SvgIcon>
);
