import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CloseCircleIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 40 39"
    sx={{
      ...sx,
      width: '40px',
      height: '39px',
      color: 'transparent',
    }}
  >
    <path
      d="M15.9167 23.5833L20 19.5M20 19.5L24.0833 15.4167M20 19.5L15.9167 15.4167M20 19.5L24.0833 23.5833M38.375 19.5C38.375 29.6482 30.1482 37.875 20 37.875C9.85177 37.875 1.625 29.6482 1.625 19.5C1.625 9.35177 9.85177 1.125 20 1.125C30.1482 1.125 38.375 9.35177 38.375 19.5Z"
      stroke="#2DF6DE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
