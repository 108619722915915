import { createRoot } from 'react-dom/client';

import App from './app';

// import '@splidejs/react-splide/dist/css/themes/splide-default.min.css';

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);
root.render(App);
