import { Box, useMediaQuery, useTheme } from '@mui/material';
import { SplideSlide } from '@splidejs/react-splide';
import { Ring } from 'entities/ring';
import { Ring1Image, Ring2Image, Ring3Image } from 'shared/assets';
import { Slider } from 'shared/ui/components';

const RINGS = [
  {
    imageUrl: Ring1Image,
    name: 'Diamond One',
    priceUsd: 29.5,
  },

  {
    imageUrl: Ring2Image,
    name: 'Gold One',
    priceUsd: 29.5,
  },

  {
    imageUrl: Ring3Image,
    name: 'Stardust One',
    priceUsd: 29.5,
  },
];

export const PreviewRings = () => {
  const theme = useTheme();
  const isDownMdLayout = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box marginTop={6} marginBottom={isDownMdLayout ? 16 : 8}>
      <Slider disableHover pagination={false}>
        {RINGS.map(({ imageUrl, name, priceUsd }) => (
          <SplideSlide key={name}>
            <Ring img={imageUrl} category={name} price={priceUsd} />
          </SplideSlide>
        ))}
      </Slider>
    </Box>
  );
};
