import { toast } from 'react-toastify';
import { coupleActions } from 'entities/couple';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { camelize, logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { modelApi } from '../ajax';
import { modelActions } from '../model';

export function* selectNftModelSaga({
  type,
  payload: { nftModelId },
}: SagaPayload<typeof modelActions.selectNftModel>) {
  yield* put(request(type));

  try {
    const data = yield* call(modelApi.selectNftModel, { nftModelId });

    yield* put(coupleActions.updateCoupleState(camelize(data)));
    yield* put(success(type));
  } catch (err: any) {
    yield* put(error(type, err));
    logger('selectNftModel', err);
    toast.error(err?.data?.detail);
  }
}

export default function* listener() {
  yield takeLatest(modelActions.selectNftModel.type, selectNftModelSaga);
}
