import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, CircularProgress, Divider, Grid, useMediaQuery } from '@mui/material';
import { Stack, styled, useTheme } from '@mui/system';
import BigNumber from 'bignumber.js';
import { Ring, ringActions, ringSelector } from 'entities/ring';
import { weddingActions } from 'entities/wedding';
import { COLOR_LIGHT_GRAY_ALPHA_2, COLOR_STROKE, COLOR_WHITE, uiSelector } from 'shared/config';
import { BORDER_MAIN_BOLD, BORDER_RADIUS_XXL } from 'shared/config/theme/common';
import { useShallowSelector } from 'shared/hooks';
import { DiamondIcon, PriceCounter, Typography } from 'shared/ui/components';
import { checkRequestStatusEquality } from 'shared/utils';
import { useTranslation } from 'react-i18next';

export const BorderWrapper = styled(Stack)(({ theme }) => ({
  border: BORDER_MAIN_BOLD,
  borderRadius: BORDER_RADIUS_XXL,
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: COLOR_LIGHT_GRAY_ALPHA_2,
  color: 'transparent',
  borderRadius: '100%',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

type DiamondContainerProps = {
  userPrice: number;
  userPriceInToken: number;
  partnerName: string;
  collectionImage: string;
  isUserWaiting: boolean;
};

export const DiamondContainer = ({
  userPrice,
  userPriceInToken,
  partnerName,
  collectionImage,
  isUserWaiting,
}: DiamondContainerProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDownMdLayout = useMediaQuery(theme.breakpoints.down('md'));
  const usdRate = useShallowSelector(ringSelector.getUsdRate);
  const uiState = useShallowSelector(uiSelector.getUI);
  const [price, setPrice] = useState(0);
  const [priceInToken, setPriceInToken] = useState(0);

  const isSelectDiamondAmountLoading = checkRequestStatusEquality(uiState[weddingActions.selectDiamond?.type]);

  const handleButtonClick = () => {
    dispatch(weddingActions.selectDiamond({ wbtcAmountUsd: price }));
  };

  const handleIncreasePrice = () => {
    switch (price) {
      case 0:
        setPrice(500);
        break;
      default:
        setPrice((prev) => prev + 100);
    }
  };
  const handleDecreasePrice = () => {
    switch (price) {
      case 500:
        setPrice(0);
        break;
      default:
        setPrice((prev) => prev - 100);
    }
  };

  useEffect(() => {
    dispatch(ringActions.getUsdRate());
  }, [dispatch]);

  useEffect(() => {
    const newPriceInToken = parseFloat(new BigNumber(price).dividedBy(usdRate).toFixed(4, 0));
    setPriceInToken(newPriceInToken);
  }, [price, usdRate]);

  return (
    <>
      <Grid item>
        <BorderWrapper>
          <Ring simple noBorder img={collectionImage} />
          <Divider
            flexItem
            orientation={isDownMdLayout ? 'horizontal' : 'vertical'}
            sx={{ borderColor: COLOR_STROKE }}
          />
          {/*<Stack width={{ xs: '100%', md: '380px' }} alignItems="center" p={4}>
            <Typography variant="h4" className="center" mb={3} maxWidth={300} textOverflow="ellipsis" overflow="hidden">
              Add diamond valued in WBTC for{' '}
              <Typography variant="h4" display="inline" className="accent" textOverflow="ellipsis" overflow="hidden">
                {partnerName}
              </Typography>
            </Typography>
            <IconWrapper>
              <DiamondIcon />
            </IconWrapper>
            <PriceCounter
              price={price}
              priceFromBack={userPrice}
              maxPrice={100000}
              onDecrease={handleDecreasePrice}
              onIncrease={handleIncreasePrice}
              disabled={isUserWaiting}
            />
            <BorderWrapper mt={4}>
              <Typography px={1.5} py={0.75}>
                {priceInToken || parseFloat(new BigNumber(userPriceInToken).toFixed(4, 0))} WBTC
              </Typography>
            </BorderWrapper>
          </Stack>*/}
        </BorderWrapper>
      </Grid>
      <Grid item>
        {!price ? (
          <Button
            variant={!price ? 'outlined' : 'contained'}
            disabled={isSelectDiamondAmountLoading || isUserWaiting}
            onClick={handleButtonClick}
            sx={{ minWidth: 198 }}
          >
            {isSelectDiamondAmountLoading || isUserWaiting ? (
              <CircularProgress size={30} sx={{ color: COLOR_WHITE }} />
            ) : (
                t('Skip')
            )}
          </Button>
        ) : (
          <Button
            variant={!price ? 'outlined' : 'contained'}
            disabled={isSelectDiamondAmountLoading || isUserWaiting}
            onClick={handleButtonClick}
            sx={{ minWidth: 192 }}
          >
            {isSelectDiamondAmountLoading || isUserWaiting ? (
              <CircularProgress size={30} sx={{ color: COLOR_WHITE }} />
            ) : (
                t('Next')
            )}
          </Button>
        )}
      </Grid>
    </>
  );
};
