import { GetWitnessWsTokenReq, RegisterWitnessReq } from 'entities/witness/model/types';
import { api } from 'shared/api/axios';
import { UserResponse } from 'shared/types';

const getGodWitnesses = () => {
  return api({
    url: `/engagement/witness/god`,
    method: 'GET',
  });
};

const registerWitnesses = (data: RegisterWitnessReq): Promise<UserResponse> => {
  return api({
    url: `/user/witness`,
    method: 'PATCH',
    data,
  });
};

const getWitnessWsToken = ({ engagementId, invitationCode }: GetWitnessWsTokenReq) => {
  return api({
    url: `/engagement/login-ws?engagement_id=${engagementId}&invitation_code=${invitationCode}`,
    method: 'POST',
  });
};

export const witnessApi = {
  getGodWitnesses,
  registerWitnesses,
  getWitnessWsToken,
};
