import { Stack, styled } from '@mui/system';
import { COLOR_WHITE_ALPHA_21 } from 'shared/config';

export const CodeBlockWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 4.375),
  width: '100%',
  height: 181,
  border: `1px solid ${COLOR_WHITE_ALPHA_21}`,
  borderRadius: 8,
  justifyContent: 'space-between',
  alignItems: 'center',
}));
