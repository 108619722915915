import { weddingActions } from 'entities/wedding';
import { weddingApi } from 'entities/wedding/api/ajax';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

export function* getReferralSaga({ type, payload: { referralCode } }: SagaPayload<typeof weddingActions.getReferral>) {
  yield* put(request(type));
  try {
    const { address } = yield* call(weddingApi.getReferral, { referralCode });

    yield put(weddingActions.updateWedding({ isReferralExist: true, referralAddress: address }));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getReferral', err);
    yield put(weddingActions.updateWedding({ isReferralExist: false, referralAddress: null }));
  }
}

export default function* listener() {
  yield takeLatest(weddingActions.getReferral.type, getReferralSaga);
}
