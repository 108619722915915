import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { coupleActions, coupleSelector } from 'entities/couple';
import { userActions, userSelector } from 'entities/user';
import { ConnectionContainer } from 'features/connection/containers';
import { socket } from 'shared/api';
import { useShallowSelector } from 'shared/hooks';
import { EngagementTypePath } from 'shared/types';

export const Connection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useShallowSelector(userSelector.getUser);
  const { engagementType, partner } = useShallowSelector(coupleSelector.getCouple);

  useEffect(() => {
    if (user.isWitness) {
      dispatch(userActions.updateUserState({ isWitness: false }));
      if (socket) socket.close();
    }
  }, [dispatch, user.isWitness]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let interval: any;

    if (partner?.promoCode && !engagementType) {
      interval = setInterval(() => dispatch(coupleActions.getCouple()), 2000);
    } else if (interval) {
      clearInterval(interval);
      interval = null;
    }
    return () => {
      clearInterval(interval);
    };
  }, [partner, dispatch, engagementType]);

  useEffect(() => {
    if (engagementType) {
      navigate(EngagementTypePath[engagementType]);
    }
  }, [engagementType, navigate]);

  return <ConnectionContainer />;
};
