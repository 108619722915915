import { all, call } from 'redux-saga/effects';

import getUserInfo from './getUserInfo';
import sendEngagementInvitation from './sendEngagementInvitation';
import sendFeedback from './sendFeedback';
import updateUserInfo from './updateUserInfo';

export function* userSagas() {
  yield all([sendFeedback, getUserInfo, updateUserInfo, sendEngagementInvitation].map(call));
}
