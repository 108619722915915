import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MessageTopIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 155 122"
    sx={{
      ...sx,
      width: '155px',
      height: '122px',
    }}
  >
    <mask id="path-1-inside-1_450_4184" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.427 7.74887L78.8302 0.249123L71.284 7.69891L16.3346 7.51763C7.49812 7.48847 0.311082 14.6282 0.281929 23.4648L0.0112492 105.51C-0.0179039 114.346 7.12188 121.533 15.9584 121.562L138.346 121.966C147.182 121.995 154.369 114.855 154.398 106.019L154.669 23.9741C154.698 15.1376 147.558 7.95056 138.722 7.9214L86.427 7.74887Z"
      />
    </mask>
    <path
      d="M78.8302 0.249123L80.2353 -1.17416L78.8302 -2.56129L77.4251 -1.17416L78.8302 0.249123ZM86.427 7.74887L85.0219 9.17215L85.6034 9.74617L86.4204 9.74886L86.427 7.74887ZM71.284 7.69891L71.2774 9.6989L72.1022 9.70162L72.6891 9.12219L71.284 7.69891ZM16.3346 7.51763L16.328 9.51762L16.3346 7.51763ZM138.722 7.9214L138.715 9.92139L138.722 7.9214ZM77.4251 1.6724L85.0219 9.17215L87.8321 6.32559L80.2353 -1.17416L77.4251 1.6724ZM72.6891 9.12219L80.2353 1.6724L77.4251 -1.17416L69.8789 6.27564L72.6891 9.12219ZM16.328 9.51762L71.2774 9.6989L71.2906 5.69892L16.3412 5.51764L16.328 9.51762ZM2.28192 23.4714C2.30743 15.7394 8.59609 9.49211 16.328 9.51762L16.3412 5.51764C6.40015 5.48484 -1.68526 13.5171 -1.71806 23.4582L2.28192 23.4714ZM2.01124 105.516L2.28192 23.4714L-1.71806 23.4582L-1.98874 105.503L2.01124 105.516ZM15.965 119.562C8.23304 119.537 1.98573 113.248 2.01124 105.516L-1.98874 105.503C-2.02154 115.444 6.01072 123.529 15.9518 123.562L15.965 119.562ZM138.352 119.966L15.965 119.562L15.9518 123.562L138.339 123.966L138.352 119.966ZM152.398 106.012C152.373 113.744 146.084 119.992 138.352 119.966L138.339 123.966C148.28 123.999 156.365 115.967 156.398 106.025L152.398 106.012ZM152.669 23.9675L152.398 106.012L156.398 106.025L156.669 23.9807L152.669 23.9675ZM138.715 9.92139C146.447 9.9469 152.694 16.2356 152.669 23.9675L156.669 23.9807C156.702 14.0396 148.669 5.95421 138.728 5.92141L138.715 9.92139ZM86.4204 9.74886L138.715 9.92139L138.728 5.92141L86.4336 5.74888L86.4204 9.74886Z"
      fill="#D8D8D8"
      mask="url(#path-1-inside-1_450_4184)"
    />
  </SvgIcon>
);
