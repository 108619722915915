import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const HeartIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 133 110"
    sx={{
      width: '156px',
      height: '128px',
      color: 'transparent',
      ...sx,
    }}
  >
    <path
      d="M96.2221 2C83.8555 2 72.961 8.18333 66.7777 17.9C60.5944 8.18333 49.6999 2 37.3333 2C17.9 2 2 17.9 2 37.3333C2 72.3721 66.7777 108 66.7777 108C66.7777 108 131.555 72.6666 131.555 37.3333C131.555 17.9 115.655 2 96.2221 2Z"
      stroke="#2DF6DE"
      strokeWidth="2.2994"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
