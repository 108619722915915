import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { COLOR_ACCENT, COLOR_BG, COLOR_BLUE, COLOR_GRAY, COLOR_STROKE, COLOR_WH } from 'shared/config/theme/colors';

export enum FontWeights {
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
}

export enum FontFamilies {
  primary = '"Inter", sans-serif',
  secondary = '"Genos", sans-serif',
  authModal = '"DM Sans", sans-serif',
}

export const colorVariations = {
  '&.accent': {
    color: COLOR_ACCENT,
  },
  '&.blue': {
    color: COLOR_BLUE,
  },
  '&.gray': {
    color: COLOR_GRAY,
  },
  '&.dark-gray': {
    color: COLOR_STROKE,
  },
  '&.white': {
    color: COLOR_WH,
  },
  '&.dark': {
    color: COLOR_BG,
  },
};

export const styleVariations = {
  '&.center': {
    textAlign: 'center',
  },
  '&.capitalize': {
    textTransform: 'capitalize',
  },
  '&.line-through': {
    textDecoration: 'line-through',
  },
};

const weightVariants = {
  '&.regular': {
    fontWeight: FontWeights.Regular,
  },
  '&.medium': {
    fontWeight: FontWeights.Medium,
  },
  '&.semi-bold': {
    fontWeight: FontWeights.SemiBold,
  },
  '&.bold': {
    fontWeight: FontWeights.Bold,
  },
};

const familyVariants = {
  '&.primary': {
    fontFamily: FontFamilies.primary,
  },
  '&.secondary': {
    fontFamily: FontFamilies.secondary,
  },
};

export const getTypographyOverrides = (theme: Theme): ComponentsOverrides['MuiTypography'] => ({
  root: {
    textTransform: 'none',
    color: theme.themeColors.mainColor,
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.Regular,
    textAlign: 'left',
    fontStyle: 'normal',
    ...colorVariations,
    ...styleVariations,
    ...weightVariants,
    ...familyVariants,
  },

  h1: {
    fontSize: 64,
    lineHeight: '77px',
    fontFamily: FontFamilies.secondary,
    fontWeight: FontWeights.Medium,

    [theme.breakpoints.down('sm')]: {
      fontSize: 46,
      lineHeight: '44px',
    },
  },

  h2: {
    fontSize: 46,
    lineHeight: '48px',
    fontFamily: FontFamilies.secondary,
    fontWeight: FontWeights.Medium,
    color: COLOR_ACCENT,

    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      lineHeight: '30px',
    },
    '&.md': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 36,
        lineHeight: '30px',
      },
    },
  },

  h3: {
    fontSize: 36,
    lineHeight: '30px',
    fontFamily: FontFamilies.secondary,
    fontWeight: FontWeights.Medium,

    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '30px',
    },
  },

  h4: {
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: FontWeights.Medium,
  },

  h5: {
    fontSize: 30,
    lineHeight: '30px',
    fontWeight: FontWeights.Medium,
    fontFamily: FontFamilies.secondary,
    color: COLOR_ACCENT,
  },

  body1: {
    fontSize: 18,
    lineHeight: '30px',
    fontWeight: FontWeights.Regular,
  },

  body2: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: FontWeights.Regular,
  },

  subtitle1: {
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: FontFamilies.secondary,
    fontWeight: FontWeights.Regular,
  },

  subtitle2: {
    fontSize: 12,
    lineHeight: '22px',
    fontWeight: FontWeights.Regular,
  },

  button: {
    fontSize: 20,
    lineHeight: '32px',
    textTransform: 'none',
  },
  caption: {
    fontSize: 15,
    lineHeight: '24px',
    fontWeight: FontWeights.Regular,
  },
});

export const getTypographyDefaultProps = (): ComponentsProps['MuiTypography'] => ({
  variant: 'body1',
});
