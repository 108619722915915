import { toast } from 'react-toastify';
import { userActions } from 'entities/user/model';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { userApi } from '../ajax';

export function* sendEngagementInvitationSaga({
  type,
  payload,
}: SagaPayload<typeof userActions.sendEngagementInvitation>) {
  yield* put(request(type));
  try {
    yield* call(userApi.sendEngagementInvitation, { ...payload });
    yield* put(userActions.updateUserState({ proposalTimeout: Date.now() + 60000 }));
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('sendEngagementInvitation', err);
    toast.error('Something went wrong');
  }
}

export default function* listener() {
  yield takeLatest(userActions.sendEngagementInvitation.type, sendEngagementInvitationSaga);
}
