import { Stack, styled, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_STROKE } from 'shared/config';
import { BORDER_RADIUS_S } from 'shared/config/theme/common';
import { EngagementType } from 'shared/types';
import { CheckIcon } from 'shared/ui/components';
import { useTranslation } from 'react-i18next';

interface WeddingTypeBlockProps {
  selectedWeddingType: EngagementType | null;
  setSelectedWeddingType: (wt: EngagementType) => void;
}
const BorderBlock = styled(Stack)(({ theme }) => ({
  borderRadius: BORDER_RADIUS_S,
  border: `1px solid ${COLOR_STROKE}`,
  padding: theme.spacing(2, 2, 3.75),
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: 1,
  '&:hover': {
    borderColor: COLOR_ACCENT,
  },
  '&.active': {
    borderColor: COLOR_ACCENT,
  },
}));

export const WeddingTypeBlock = ({ selectedWeddingType, setSelectedWeddingType }: WeddingTypeBlockProps) => {
  const { t, i18n } = useTranslation();
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} display={{ xs: 'none' }}>
      <BorderBlock
        className={selectedWeddingType === EngagementType.Person ? 'active' : ''}
        flex={{ xs: '0 0 50%' }}
        onClick={() => setSelectedWeddingType(EngagementType.Person)}
      >
        <Typography variant="body2">{t('Marry')}</Typography>
        {selectedWeddingType === EngagementType.Person && <CheckIcon />}
      </BorderBlock>
      {/* <BorderBlock
        className={selectedWeddingType === EngagementType.Model ? 'active' : ''}
        onClick={() => setSelectedWeddingType(EngagementType.Model)}
      >
        <Typography variant="body2" className={selectedWeddingType === EngagementType.Model ? 'active' : ''}>
          Activate Commitment ring
        </Typography>
        {selectedWeddingType === EngagementType.Model && <CheckIcon />}
      </BorderBlock> */}
    </Stack>
  );
};
