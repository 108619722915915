import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CopyIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 28 28"
    sx={{
      width: '28px',
      height: '28px',
      ...sx,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 15.1667V14C10.5 12.0703 12.0703 10.5 14 10.5H15.1667V6.6115C15.1667 6.18217 14.8178 5.83333 14.3885 5.83333H6.6115C6.18217 5.83333 5.83333 6.18217 5.83333 6.6115V14.3885C5.83333 14.8178 6.18217 15.1667 6.6115 15.1667H10.5ZM10.5 17.5H6.6115C4.89533 17.5 3.5 16.1047 3.5 14.3885V6.6115C3.5 4.89533 4.89533 3.5 6.6115 3.5H14.3885C16.1047 3.5 17.5 4.89533 17.5 6.6115V10.5H21C22.9297 10.5 24.5 12.0703 24.5 14V21C24.5 22.9297 22.9297 24.5 21 24.5H14C12.0703 24.5 10.5 22.9297 10.5 21V17.5ZM12.8333 14C12.8333 13.3572 13.3572 12.8333 14 12.8333H21C21.644 12.8333 22.1667 13.3572 22.1667 14V21C22.1667 21.6428 21.644 22.1667 21 22.1667H14C13.3572 22.1667 12.8333 21.6428 12.8333 21V14Z"
      fill="#2DF6DE"
    />
    <mask
      id="mask0_384_3461"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="22"
      height="22"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 15.1667V14C10.5 12.0703 12.0703 10.5 14 10.5H15.1667V6.6115C15.1667 6.18217 14.8178 5.83333 14.3885 5.83333H6.6115C6.18217 5.83333 5.83333 6.18217 5.83333 6.6115V14.3885C5.83333 14.8178 6.18217 15.1667 6.6115 15.1667H10.5ZM10.5 17.5H6.6115C4.89533 17.5 3.5 16.1047 3.5 14.3885V6.6115C3.5 4.89533 4.89533 3.5 6.6115 3.5H14.3885C16.1047 3.5 17.5 4.89533 17.5 6.6115V10.5H21C22.9297 10.5 24.5 12.0703 24.5 14V21C24.5 22.9297 22.9297 24.5 21 24.5H14C12.0703 24.5 10.5 22.9297 10.5 21V17.5ZM12.8333 14C12.8333 13.3572 13.3572 12.8333 14 12.8333H21C21.644 12.8333 22.1667 13.3572 22.1667 14V21C22.1667 21.6428 21.644 22.1667 21 22.1667H14C13.3572 22.1667 12.8333 21.6428 12.8333 21V14Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_384_3461)" />
  </SvgIcon>
);
