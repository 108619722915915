import { FC } from 'react';
import { Box, styled } from '@mui/material';
import { User, UserRole } from 'entities/user';
import { HeartIcon } from 'shared/ui/components';

type HeartUserProps = {
  avatar: string;
  firstName: string;
  lastName: string;
};

type HeartWithUsersProps = {
  femaleProps: HeartUserProps;
  manProps: HeartUserProps;
  size?: 'medium' | 'large';
};

const StyledGrid = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.large {
    width: 327px;
    height: 128px;
  }
  &.medium {
    width: 238px;
    height: 106px;
  }

  * {
    box-sizing: border-box;
  }

  > * {
    z-index: 1;
  }
`;

export const HeartWithUsers: FC<HeartWithUsersProps> = ({ femaleProps, manProps, size = 'large' }) => {
  return (
    <StyledGrid className={size}>
      <User {...femaleProps} role={UserRole.Female} simple size={size} />
      <HeartIcon
        sx={{
          position: 'absolute',
          zIndex: 0,
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '100%',
          height: '100%',
        }}
      />
      <User {...manProps} role={UserRole.Man} simple size={size} />
    </StyledGrid>
  );
};
