import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_WHITE, COLOR_WHITE_ALPHA_5 } from '../colors';
import { FontWeights } from '../Typography';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiRadio = (theme: Theme): ComponentsOverrides['MuiCheckbox'] => {
  const colorCommon = {
    background: COLOR_WHITE_ALPHA_5,
    color: 'transparent',
    border: `2px solid ${theme.themeColors.colorRadioAndCheckboxBorder}`,
    '&:hover': {
      color: 'transparent',
      background: COLOR_WHITE_ALPHA_5,
      borderColor: theme.themeColors.colorRadioAndCheckboxBorderHover,
    },
    '&.Mui-checked': {
      background: theme.themeColors.colorRadioBackgroundChecked,
      color: theme.themeColors.colorRadioBackgroundChecked,
      border: `6px solid ${theme.themeColors.colorRadioAndCheckboxChecked}`,
    },
  };
  return {
    root: {
      padding: 0,
      height: 24,
      width: 24,
      borderRadius: '50%',
      borderWidth: 2,
      borderStyle: 'solid',
      overflow: 'hidden',
      '& + .MuiFormControlLabel-label': {
        fontSize: '16px',
        fontWeight: FontWeights.Medium,
      },
      '&.Mui-disabled': {
        color: 'transparent',
        '& + .MuiFormControlLabel-label': {
          color: COLOR_WHITE,
        },
      },
    },
    colorPrimary: colorCommon,
    colorSecondary: colorCommon,
  };
};

export const getMuiRadioDefaultProps = (): ComponentsProps['MuiCheckbox'] => ({
  color: 'primary',
});
