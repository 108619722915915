import { FC } from 'react';
import { Box, styled } from '@mui/material';
import { COLOR_ACCENT, COLOR_BG, COLOR_LIGHT_GRAY_ALPHA_2 } from 'shared/config';
import { BORDER_ACCENT_BOLD, BORDER_MAIN_BOLD } from 'shared/config/theme/common';
import { CheckCircle } from 'shared/ui/components';

interface IGodWitness {
  img: string;
  simple?: boolean;
  withChecks?: boolean;
  activeFirstCheck?: boolean;
  activeSecondCheck?: boolean;
  activeBorder?: boolean;
}

const RelativeWrapper = styled(Box)`
  position: relative;
`;

const StyledGrid = styled(Box)<{ noBorder?: boolean; activeBorder: boolean }>`
  background-color: ${COLOR_BG};
  padding: ${({ noBorder, theme }) => (noBorder ? 'none' : theme.spacing(2.5))};
  * {
    box-sizing: border-box;
  }

  border-radius: 16px;
  width: 344px;
  height: 417px;

  ${({ theme, noBorder, activeBorder }) => `
  ${!noBorder && `border: ${activeBorder ? BORDER_ACCENT_BOLD : BORDER_MAIN_BOLD};`}
  ${theme.breakpoints.down('md')} {
    width: 281px;
    height: 338px;
    padding: ${theme.spacing(1.5)}
  `}
`;

const StyledImageWrapper = styled(Box)`
  overflow: hidden;
  width: 100%;
  height: 100%;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const CheckWrapper = styled(Box)<{ active?: boolean }>`
  zindex: 3;
  position: absolute;
  right: 20px;
  &.first {
    top: 20px;
  }
  &.second {
    top: 58px;
  }
  * {
    stroke: ${({ active }) => (active ? COLOR_ACCENT : COLOR_LIGHT_GRAY_ALPHA_2)};
  }
  ${({ theme }) => `${theme.breakpoints.down('md')} {
    right: 10px;
    &.second {
      top: 56px;
    }
  }`}
`;

export const GodWitness: FC<IGodWitness> = ({
  img,
  simple,
  withChecks,
  activeFirstCheck,
  activeSecondCheck,
  activeBorder = false,
}) => {
  return (
    <RelativeWrapper>
      <StyledGrid noBorder={simple} activeBorder={!simple && activeBorder} className="border-wrapper">
        {img && (
          <StyledImageWrapper>
            <img src={img} alt="witness" />
          </StyledImageWrapper>
        )}
      </StyledGrid>
      {withChecks && (
        <>
          <CheckWrapper className="first" active={activeFirstCheck}>
            <CheckCircle />
          </CheckWrapper>
          <CheckWrapper className="second" active={activeSecondCheck}>
            <CheckCircle />
          </CheckWrapper>
        </>
      )}
    </RelativeWrapper>
  );
};
