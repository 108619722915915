/* eslint-disable @typescript-eslint/no-unused-vars */

import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { BORDER_MAIN } from '../common';

import { CustomExpandIcon } from './AccordionExpandIcon';

export const getMuiAccordion = (theme: Theme): ComponentsOverrides['MuiAccordion'] => ({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    width: '100%',
    '&::before': {
      display: 'none',
    },
  },
});

export const getMuiAccordionDetails = (theme: Theme): ComponentsOverrides['MuiAccordionDetails'] => ({
  root: {
    padding: theme.spacing(1.5, 0),
  },
});
export const getMuiAccordionSummary = (theme: Theme): ComponentsOverrides['MuiAccordionSummary'] => ({
  root: {
    padding: 0,
    borderBottom: BORDER_MAIN,
  },
  content: {
    margin: theme.spacing(2.5, 0),
  },
});

export const getMuiAccordionPropsDefaultProps = (): ComponentsProps['MuiAccordion'] => ({
  disableGutters: true,
  elevation: 0,
  square: true,
});

export const getMuiAccordionSummaryPropsDefaultProps = (): ComponentsProps['MuiAccordionSummary'] => ({
  expandIcon: <CustomExpandIcon />,
});
