import { configureStore } from '@reduxjs/toolkit';
import { CoupleState } from 'entities/couple';
import { ModalState } from 'entities/modal';
import { ModelState } from 'entities/models';
import { RingState } from 'entities/ring/model';
import { UserState } from 'entities/user';
import { WeddingState } from 'entities/wedding';
import { WitnessState } from 'entities/witness/model';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import { ignoredActions } from './ignoredSerializableActions';
import reducer from './rootReducer';
import rootSaga from './rootSaga';

type MyPersistConfig<T> = PersistConfig<T> & { whitelist: (keyof T)[] };

export type State = {
  modal: ModalState;
  user: UserState;
  couple: CoupleState;
  model: ModelState;
  wedding: WeddingState;
  ring: RingState;
  witness: WitnessState;
};

const sagaMiddleware = createSagaMiddleware();
const userPersistConfig: MyPersistConfig<UserState> = {
  key: 'user',
  storage,
  whitelist: ['address', 'id', 'email', 'proposalTimeout'],
};
const reducers = {
  ...reducer,
  user: persistReducer(userPersistConfig, reducer.user),
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: { ignoredActions },
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
