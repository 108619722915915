import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckCircle: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 35 36"
    sx={{
      color: 'transparent',
      ...sx,
      width: '34px',
      height: '35px',
    }}
  >
    <path
      d="M13.125 18L16.0417 21L21.875 15M30.625 18C30.625 25.4558 24.7487 31.5 17.5 31.5C10.2513 31.5 4.375 25.4558 4.375 18C4.375 10.5442 10.2513 4.5 17.5 4.5C24.7487 4.5 30.625 10.5442 30.625 18Z"
      stroke="#2DF6DE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
