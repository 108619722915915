import { qrImage } from 'shared/assets';

import { witnessMock } from './witness.mock';

export const certificateMock = {
  id: 13,
  txLink: '/',
  fianceeName: 'Anna Bell',
  fianceName: 'Mikle Tomson',
  witnessName: witnessMock.name,
  witnessImg: witnessMock.img,
  date: Date.now(),
  qrImg: qrImage,
};
