import { SvgIcon, SvgIconProps } from '@mui/material';

export const Twitter = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon width="44" height="44" viewBox="0 0 44 44" fill="none" sx={sx} {...props}>
    <path
      d="M16.5201 7.20801H7.60352L28.3845 36.8119H37.2277L16.5201 7.20801ZM11.0915 9.03124H15.5864L33.7464 34.9887H29.3181L11.0915 9.03124Z"
      fill="white"
    />
    <path d="M36.4399 7.1875H34.1857L24.3555 18.4308L25.4092 19.9366L36.4399 7.1875Z" fill="white" />
    <path d="M8.27148 36.8126H10.519L20.5226 25.5626L19.4689 24.0635L8.27148 36.8126Z" fill="white" />
  </SvgIcon>
);
