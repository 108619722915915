import { witnessActions } from 'entities/witness/model';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { camelize, logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { witnessApi } from '../ajax';

export function* getGodWitnessesSaga({ type }: SagaPayload<typeof witnessActions.getGodWitnesses>) {
  yield* put(request(type));
  try {
    const data = yield* call(witnessApi.getGodWitnesses);
    const gods = camelize(data);
    yield* put(witnessActions.updateWitnessState({ gods }));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getGodWitnesses', err);
  }
}

export default function* listener() {
  yield takeLatest(witnessActions.getGodWitnesses.type, getGodWitnessesSaga);
}
