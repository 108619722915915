import { SvgIcon, SvgIconProps } from '@mui/material';

export const BlurEllipsePurple = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 1435 700" fill="none" sx={{ width: 1435, height: 700, overflow: 'visible', ...sx }} {...props}>
    <g opacity="0.5" filter="url(#filter0_f_450_4712)">
      <ellipse cx="717.5" cy="692.5" rx="328.5" ry="303.5" fill="#EB99F9" fillOpacity="0.45" />
    </g>
    <defs>
      <filter
        id="filter0_f_450_4712"
        x="0"
        y="0"
        width="1435"
        height="1385"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="194.5" result="effect1_foregroundBlur_450_4712" />
      </filter>
    </defs>
  </SvgIcon>
);
