import { Box, Stack, Typography } from '@mui/material';

type ModelContactsProps = {
  imageUrl: string;
  name: string;
  contacts: string;
};

export const ModelContacts = ({ imageUrl, name, contacts }: ModelContactsProps) => {
  return (
    <Stack alignItems="center">
      <Box
        sx={{
          mt: 2,
          mb: 2,
          width: 150,
          height: 150,
          overflow: 'hidden',
          borderRadius: '28px',
          img: { width: '100%', height: '100%', objectFit: 'cover' },
        }}
      >
        <img src={imageUrl} alt="model" />
      </Box>
      <Typography variant="h5" mb={1}>
        {name}
      </Typography>
      <Typography variant="caption" mb={1}>
        Contacts:
      </Typography>
      <Typography variant="caption" className="accent">
        {contacts}
      </Typography>
    </Stack>
  );
};
