import { Box, Stack, Typography } from '@mui/material';
import { useIsDownLayoutMd } from 'shared/hooks';

import { Ellipse } from '../Icons';
/*import { VDayLogo } from '../Icons/VDayLogo';*/
import { useTranslation } from 'react-i18next';

export const Preview = () => {
  const { t, i18n } = useTranslation();
  const isDownMdLayout = useIsDownLayoutMd();

  return (
    <Stack
      position="relative"
      alignItems="center"
      sx={{
        animation: { xs: 'none', lg: '0.5s linear slidein' },
        '@keyframes slidein': {
          from: {
            marginTop: '100%',
          },

          to: {
            marginTop: 7,
          },
        },
      }}
    >
      <Box position="absolute" top={{ xs: 20, sm: -140 }} mx="auto" zIndex={0}>
        <Ellipse
          sx={{
            position: 'relative',
            left: { xs: 0, lg: 15 },
            width: { xs: 300, sm: 500, lg: 715 },
            height: { xs: 'auto', sm: 716 },
          }}
        />
      </Box>
        <Typography
        maxWidth={{ xs: '100%', sm: 850 }}
        letterSpacing={1}
        fontSize={{ xs: 18, sm: 24 }}
        lineHeight={1}
        className="center"
        mt={isDownMdLayout ? 6 : 1.75}
      >{t('main')}:
      </Typography>
        {/*<Box
        width={{ xs: '100%', lg: 850 }}
        sx={{
          textAlign: 'center',
          img: { maxWidth: { xs: 300, sm: 500, lg: 600 }, width: '100%' },
        }}
      >
        <VDayLogo sx={{ width: { xs: 300, sm: 500, lg: 600 }, height: 'auto' }} />
      </Box>
      <Typography
        maxWidth={{ xs: '100%', sm: 850 }}
        letterSpacing={1}
        fontSize={{ xs: 18, sm: 24 }}
        lineHeight={1}
        mt={{ xs: '-20px', sm: '-35px' }}
        mb={{ xs: 0, sm: 2 }}
        className="center"
      >

      </Typography>*/}
      <Typography
        maxWidth={{ xs: '100%', sm: 850 }}
        letterSpacing={1}
        fontSize={{ xs: 18, sm: 24 }}
        lineHeight={1}
        className="center"
        mt={4}
      >
          {t('main_description')}
      </Typography>
      {/* <Typography
        fontSize={24}
        fontWeight={FontWeights.Medium}
        fontFamily={FontFamilies.primary}
        className="center accent"
        component="h1"
        mt={isDownMdLayout ? 3.5 : 2.25}
      >
        The very first blockchain marriage service:
      </Typography> */}
    </Stack>
  );
};
