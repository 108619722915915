import { FC } from 'react';
import { Avatar, Box, styled, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_DARK, COLOR_PURPURE, COLOR_VIOLET, COLOR_WHITE } from 'shared/config';
import { FontFamilies, FontWeights } from 'shared/config/theme/Typography';

export enum UserStatus {
  Online = 'Online',
  Offline = 'Offline',
  Owns = 'owns the ring',
  You = 'You',
}

export enum UserRole {
  Female = 'Female',
  Man = 'Man',
  Witness = 'Witness',
}

const roleBgMapping: { [key in keyof typeof UserRole]: string } = {
  [UserRole.Female]: COLOR_PURPURE,
  [UserRole.Man]: COLOR_ACCENT,
  [UserRole.Witness]: COLOR_VIOLET,
};

export type UserProps = {
  avatar?: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  withFullName?: boolean;
  status?: UserStatus;
  id?: number;
  simple?: boolean;
  noBorder?: boolean;
  withRing?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const StyledGrid = styled(Box)<{ noBorder?: boolean; smallPadding?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  column-gap: ${({ theme }) => theme.spacing(1.5)};
  padding: ${({ theme }) => theme.spacing(1.5)};

  ${({ smallPadding, theme }) =>
    smallPadding &&
    `
    ${theme.breakpoints.down('md')} {
      padding:${theme.spacing(1)}
    } 
  `}
  align-items: center;

  ${({ noBorder, theme }) =>
    !noBorder &&
    `
      border: 2px solid #5C5A75;
      border-radius: 16px;
      width: 191px;
      ${theme.breakpoints.down('sm')} {
          width: 154px;
        } 
    `}
`;

const StyledAvatar = styled(Avatar)<{ role: UserRole }>`
  background: ${({ role }) => roleBgMapping[role]};

  & > p {
    color: ${({ role }) => (role === UserRole.Man ? COLOR_DARK : COLOR_WHITE)};
  }

  &.small {
    width: 40x;
    height: 40px;
  }
  &.medium {
    width: 72px;
    height: 72px;
  }
  &.large {
    width: 100px;
    height: 100px;
  }

  grid-row-start: 1;
  grid-row-end: 3;
`;

const StyledId = styled(Typography)`
  grid-row: 1 / 3;
  grid-column: 3;
  margin-right: ${({ theme }) => theme.spacing(3.5)};
  font-family: ${FontFamilies.secondary};
`;

const StyledAvatarTypography = styled(Typography)`
  font-weight: ${FontWeights.Regular};
  &.small {
    font-size: 16px;
    line-height: 24px;
  }
  &.medium {
    font-size: 17px;
    line-height: 25px;
  }
  &.large {
    font-size: 25px;
    line-height: 37px;
  }
`;

const StyledName = styled(Typography)<{ centered?: boolean; smallFont?: boolean }>`
  font-weight: 400;
  line-height: 1.25rem;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;

  ${({ smallFont, theme }) =>
    smallFont &&
    `
  font-weight: 700;
  ${theme.breakpoints.down('md')} {
    font-size: 0.75rem;
    line-height: 1rem;
} 
`}

  ${({ centered }) => centered && 'grid-row: 1 / 3'}
`;

const StyledStatus = styled(Typography)`
  color: ${COLOR_ACCENT};
  font-size: 0.75rem;
  line-height: 0.75rem;
`;

export const User: FC<UserProps> = ({
  avatar,
  firstName,
  lastName,
  withFullName = false,
  role,
  status,
  id,
  simple,
  noBorder,
  withRing,
  size = 'small',
}) => {
  if (simple)
    return (
      <StyledAvatar className={size} src={avatar} role={role}>
        <StyledAvatarTypography className={size}>{`${firstName?.charAt(0)}${lastName?.charAt(
          0,
        )}`}</StyledAvatarTypography>
      </StyledAvatar>
    );

  return (
    <StyledGrid noBorder={noBorder} smallPadding={withRing}>
      <StyledAvatar className={size} src={avatar} role={role}>
        <StyledAvatarTypography className={size}>{`${firstName?.charAt(0)}${lastName?.charAt(
          0,
        )}`}</StyledAvatarTypography>
      </StyledAvatar>
      <StyledName centered={!status} smallFont={withRing}>
        {withFullName ? `${firstName}${withRing ? '\n' : ' '}${lastName}` : firstName}
      </StyledName>
      {status && <StyledStatus>{status}</StyledStatus>}
      {id && <StyledId>#{id}</StyledId>}
    </StyledGrid>
  );
};
