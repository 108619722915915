import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_ACCENT, COLOR_BLACK, COLOR_WHITE } from '../colors';
import { BORDER_ALPHA, BORDER_RADIUS_L } from '../common';
import { FontFamilies, FontWeights } from '../Typography';

export const getMuiButtonOverrides = (theme: Theme): ComponentsOverrides['MuiButton'] => ({
  root: {
    padding: theme.spacing(1.5, 3),
    borderRadius: '141px',
    backgroundColor: theme.themeColors.background,
    lineHeight: '30px',
    fontFamily: FontFamilies.primary,
    fontWeight: FontWeights.SemiBold,
    fontSize: 18,
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:disabled': {
      color: theme.themeColors.colorButtonBorderDisabled,
      border: `2px solid ${theme.themeColors.colorButtonBorderDisabled}`,
      '&.active-text-disabled': {
        color: theme.themeColors.colorButtonOutlinedColor,
        borderRadius: BORDER_RADIUS_L,
        background: 'transparent',
        textTransform: 'inherit',
        fontWeight: FontWeights.Medium,
      },
      '&.active-bg-disabled': {
        color: theme.themeColors.colorButtonContainedColor,
        background: theme.themeColors.colorButtonContainedBackground,
        border: theme.themeColors.colorButtonContainedBorder,
        svg: { color: COLOR_BLACK },
      },
      '&.disabled-opacity': {
        color: theme.themeColors.colorButtonContainedColor,
        background: theme.themeColors.colorButtonContainedBackground,
        border: theme.themeColors.colorButtonContainedBorder,
        opacity: 0.2,
        svg: { color: COLOR_BLACK },
      },
    },
    '&:hover': {
      boxShadow: 'none',
    },
    '&.gray': {
      padding: theme.spacing(1.5, 3),
      border: BORDER_ALPHA,
      height: 48,
      textTransform: 'none',
      fontSize: 15,
      color: COLOR_WHITE,
      '&:hover, &:active, &.Mui-focused': {
        background: theme.themeColors.colorsTextFieldBackground,
      },
    },
  },
  contained: {
    height: 56,
    color: theme.themeColors.colorButtonContainedColor,
    background: theme.themeColors.colorButtonContainedBackground,
    border: theme.themeColors.colorButtonContainedBorder,
    '&:hover': {
      background: theme.themeColors.colorButtonContainedBackgroundHover,
      border: theme.themeColors.colorButtonContainedBorderHover,
    },
  },
  outlined: {
    height: 56,
    color: theme.themeColors.colorButtonOutlinedColor,
    background: theme.themeColors.colorButtonOutlinedBackground,
    border: theme.themeColors.colorButtonOutlinedBorder,
    '&:hover': {
      background: theme.themeColors.colorButtonOutlinedBackgroundHover,
      color: theme.themeColors.colorButtonOutlinedColorHover,
      border: theme.themeColors.colorButtonOutlinedBorderHover,
    },
  },

  text: {
    padding: 0,
    minWidth: 0,
    fontSize: 16,
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },
    '&:active': {
      background: 'transparent',
    },
    '&:disabled': {
      border: 'none',
    },
    '&.blue': {
      color: COLOR_ACCENT,
    },
    '&.underline': {
      textDecoration: 'underline',
    },
  },
  sizeMedium: {
    fontSize: '16px',
  },
});

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  size: 'medium',
});
