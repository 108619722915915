/* eslint-disable no-console */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { BACKEND_URL } from 'shared/config/constants';
import { camelize, decamelize } from 'shared/utils';

const client: AxiosInstance = axios.create({
  baseURL: `${BACKEND_URL}v1/`,
  responseType: 'json' as const,
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: (request) => {
    return JSON.stringify(decamelize(request));
  },
  transformResponse: (response) => {
    return camelize(JSON.parse(response));
  },
  paramsSerializer: {
    serialize: (params) => {
      return new URLSearchParams(decamelize(params)).toString();
    },
  },
});

export const api = (options: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    console.log('ERROR', error);
    console.error('Request Failed:', error.config);

    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
    } else {
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  const token = Cookies.get('token');
  // console.log('token', token);
  if (token) {
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    client.defaults.headers.common.Authorization = '';
  }

  return client(options).then(onSuccess).catch(onError);
};
