import { Box, styled, Typography } from '@mui/material';
import { MessageRightIcon, MessageTopIcon } from 'shared/ui/components';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  '&.right': {
    padding: theme.spacing(3),
    paddingRight: theme.spacing(4.5),
  },
  '&.left': {
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(4.5),
  },
  '&.top': {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    lineHeight: '16px',
  },
}));

type MessageProps = {
  orientation?: 'left' | 'right' | 'top';
  text: string;
};

export const Message = ({ orientation = 'right', text }: MessageProps) => {
  return (
    <Box position="relative" width={orientation === 'top' ? 155 : 360}>
      {orientation === 'top' ? (
        <MessageTopIcon sx={{ position: 'absolute' }} />
      ) : (
        <MessageRightIcon
          sx={{ position: 'absolute', transform: orientation === 'left' ? 'rotate(180deg)' : 'none' }}
        />
      )}
      <StyledTypography variant={orientation === 'top' ? 'subtitle2' : 'body1'} className={orientation}>
        {text}
      </StyledTypography>
    </Box>
  );
};
