import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'app/store';

import {
  IConnectWalletPayload,
  IInviteWitnessPayload,
  ILoadingPayload,
  IMoonpayPayload,
  ITellAboutYourselfPayload,
} from '../ui';

export enum ModalType {
  ConnectWallet = 'ConnectWallet',
  Loading = 'Loading',
  Moonpay = 'Moonpay',
  TellAboutYourself = 'TellAboutYourself',
  InviteWitness = 'InviteWitness',
}

// type IBaseModal = { onClose?: () => void };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IModal<T extends { type: ModalType; data: any } = { type: ModalType; data: any }> {
  onClose?: () => void;
  data: T['data'];
}
export type IModalPayload =
  | IConnectWalletPayload
  | ILoadingPayload
  | ITellAboutYourselfPayload
  | IInviteWitnessPayload
  | IMoonpayPayload;
export type ModalState = {
  modal: IModalPayload | null;
};

export const initialState: ModalState = {
  modal: null,
};

export const modalSelector = (state: State): ModalState => state.modal;

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state: ModalState, action: PayloadAction<ModalState['modal']>) {
      state.modal = action.payload;
    },

    closeModal(state: ModalState) {
      state.modal = null;
    },
  },
});

export const { reducer: modalReducer } = modalSlice;
export const { actions: modalActions } = modalSlice;
