import { ringActions } from 'entities/ring/model';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { camelize, logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { ringApi } from '../ajax';

export function* getUsdRateSaga({ type }: SagaPayload<typeof ringActions.getUsdRate>) {
  yield* put(request(type));
  try {
    const data = yield* call(ringApi.getUsdRate);
    const { usdRate } = camelize(data);
    yield* put(ringActions.updateRingState({ usdRate }));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getUsdRate', err);
  }
}

export default function* listener() {
  yield takeLatest(ringActions.getUsdRate.type, getUsdRateSaga);
}
