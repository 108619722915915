import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { coupleSelector } from 'entities/couple';
import { weddingActions, weddingSelector } from 'entities/wedding';
import { COLOR_LIGHT_GRAY_ALPHA_2 } from 'shared/config';
import { BORDER_RADIUS_XXL } from 'shared/config/theme/common';
import { useShallowSelector } from 'shared/hooks';
import { CloseCircleIcon, CopyTextField } from 'shared/ui/components';

import { modalActions, ModalType } from '../model';

export interface IInviteWitnessPayload {
  type: ModalType.InviteWitness;
  data?: null;
}

export const InviteWitnessModal = () => {
  const dispatch = useDispatch();
  const { id: engagementId, invitationCode } = useShallowSelector(weddingSelector.getWedding);
  const { engagementType } = useShallowSelector(coupleSelector.getCouple);
  const witnessUrl = `${window.location.origin}/invite?engagementId=${engagementId}&invitationCode=${invitationCode}&engagementType=${engagementType}`;

  const handleNextClick = () => {
    dispatch(weddingActions.confirmWitnessInvite());
  };

  useEffect(() => {
    dispatch(weddingActions.getWitnessInviteCode());
  }, [dispatch]);

  return (
    <Stack
      spacing={{ xs: 5, sm: 3.5 }}
      sx={{
        paddingTop: { xs: 3, sm: 0 },
        paddingBottom: { xs: 6.5, sm: 8 },
        maxWidth: { xs: 328, sm: 654 },
        backgroundColor: 'rgb(25, 24, 54)',
        width: '100%',
        borderRadius: BORDER_RADIUS_XXL,
        position: 'relative',
      }}
    >
      <CloseCircleIcon
        sx={{ cursor: 'pointer', position: 'absolute', right: '29px', top: '30px' }}
        onClick={() => dispatch(modalActions.closeModal())}
      />
      <Typography variant="h3" className="center" sx={{ px: 2, py: 0.75 }}>
        Invite a person
      </Typography>
      <Divider sx={{ background: COLOR_LIGHT_GRAY_ALPHA_2 }} />
      <Stack alignItems="center" spacing={{ xs: 3.5, sm: 5 }}>
        <Stack alignItems="center" px={{ xs: 2.75, sm: 10.75 }} spacing={2} width="100%">
          <Typography variant="body2">Share invitation link</Typography>
          <CopyTextField
            text={invitationCode ? witnessUrl : 'Loading...'}
            variant="outlined"
            disabled={!invitationCode}
          />
        </Stack>
        <Button variant="contained" onClick={handleNextClick} sx={{ width: 189 }}>
          Next
        </Button>
      </Stack>
    </Stack>
  );
};
