import { Box, Typography } from '@mui/material';
import { Stack, styled } from '@mui/system';
import { format, getYear } from 'date-fns';
import { HeartWithUsers } from 'entities/heart-with-users';
import { User, UserRole } from 'entities/user';
import { weddingSelector } from 'entities/wedding';
import { logoImage } from 'shared/assets';
import { BORDER_MAIN_BOLD, BORDER_RADIUS_XXL } from 'shared/config/theme/common';
import { useShallowSelector } from 'shared/hooks';
import { PolygonIcon } from 'shared/ui/components';
import { useTranslation } from 'react-i18next';

export const BorderWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  border: BORDER_MAIN_BOLD,
  borderRadius: BORDER_RADIUS_XXL,
  alignItems: 'center',
  padding: theme.spacing(3, 4, 2.25),
  minWidth: 800,
  minHeight: 800,
  [theme.breakpoints.down('md')]: {
    minWidth: 'unset',
  },
  '*': {
    textAlign: 'center',
  },
}));
type CertificateUserProps = {
  firstName: string;
  lastName: string;
  avatar: string;
};

type CertificateContainerProps = {
  femaleProps: CertificateUserProps;
  manProps: CertificateUserProps;
};
export const CertificateContainer = ({ femaleProps, manProps }: CertificateContainerProps) => {
    const { t, i18n } = useTranslation();
  const { witness, certificate, marriedAt } = useShallowSelector(weddingSelector.getWedding);
  const date = new Date(marriedAt || 0);
  const witnessDisplayData = witness
    ? {
        firstName: witness.user?.firstName || '',
        lastName: witness.user?.lastName || '',
        avatar: witness.user?.profileImage || '',
      }
    : null;
  return (
    <BorderWrapper pb={{xs: '120px'}}>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography className="secondary">#{certificate?.externalTokenId || ''}</Typography>
          <Typography className="center primary">
            {format(date, 'do')} {t('certificate_Container_5')} {format(date, 'LLLL')} {getYear(date)}
          </Typography>
        <PolygonIcon />
      </Stack>
      <Stack spacing={2} alignItems="center" mt={3}>
        <Typography variant="h2" className="blue center" sx={{lineHeight: '35px'}}>
            {t('certificate_Container_1')}
          <br />
          {t('certificate_Container_1_1')}
          <br />
            {t('certificate_Container_2')}
        </Typography>
      </Stack>
      <Stack spacing={2} alignItems="center" my={3} mt={{ xs: 4.5, md: 10 }}>
          <Typography className="primary">{t('certificate_Container_3')}</Typography>
        <Typography variant="h4" className="secondary blue" fontSize={30} pb={2}>
          {`${femaleProps.firstName} ${femaleProps.lastName}`} & {`${manProps.firstName} ${manProps.lastName}`}
        </Typography>
        <HeartWithUsers size="medium" femaleProps={femaleProps} manProps={manProps} />
        <Typography variant="body2" className="center">
            {t('certificate_Container_7')}
          <br />
          <Typography component="span" variant="h4" className="secondary blue" fontSize={30}>
              {t('certificate_Container_8')}
          </Typography>
        </Typography>
        <Typography variant="body2" className="center">
            {t('certificate_Container_9')} <br /> {t('certificate_Container_10')}
        </Typography>
        {witnessDisplayData ? (
          <>
            <User {...witnessDisplayData} role={UserRole.Witness} simple size="medium" />
            <Typography variant="subtitle1" className="primary" fontSize={16}>
                {t('certificate_Container_11')} {`${witnessDisplayData?.firstName || ''} ${witnessDisplayData?.lastName || ''}`}
            </Typography>
          </>
        ) : (
          <Typography variant="subtitle1" className="primary" fontSize={16}>
              {t('certificate_Container_12')}
          </Typography>
        )}
      </Stack>
      <Box component="img" src={logoImage} alt="web3wed logo" sx={{ width: 98, height: 94, position: 'absolute', left: '22px', bottom: '0', mb: 3.5, mt: 'auto' }} />
      <Box
        component="img"
        src={certificate?.qrCodeUrl || ''}
        alt="qr"
        sx={{
          width: 94,
          height: 94,
          position: 'absolute',
          right: 22,
          bottom: 22,
          borderRadius: '5px',
        }}
      />
    </BorderWrapper>
  );
};
