import { WsWeddingStates } from 'entities/wedding';
import {
  Ceremony,
  Certificate,
  Connection,
  FianceCode,
  Home,
  Models,
  Payment,
  RingPrice,
  RingsPreview,
  SelectRing,
  SelectWitness,
  WitnessConnection,
} from 'pages';
import { RoutesProps } from 'shared/types';

export const routes: RoutesProps = {
  home: {
    path: '/',
    component: <Home />,
  },
  connection: {
    path: '/connection',
    component: <Connection />,
    isProtected: true,
  },
  fianceCode: {
    path: '/fiance-code',
    component: <FianceCode />,
    isProtected: true,
  },
  model: {
    path: '/model',
    component: <Models />,
    isProtected: true,
  },
  witnessConnection: {
    path: '/invite',
    component: <WitnessConnection />,
  },
  selectRing: {
    path: '/select-ring',
    component: <SelectRing />,
    isProtected: true,
  },
  ringPrice: {
    path: '/ring-price',
    component: <RingPrice />,
    isProtected: true,
  },
  ringsPreview: {
    path: '/rings-preview',
    component: <RingsPreview />,
    isProtected: true,
  },
  payment: {
    path: '/payment',
    component: <Payment />,
    isProtected: true,
  },
  witness: {
    path: '/witness',
    component: <SelectWitness />,
    isProtected: true,
  },
  ceremony: {
    path: '/ceremony',
    component: <Ceremony />,
    isProtected: true,
  },
  certificate: {
    path: '/certificate',
    component: <Certificate />,
    isProtected: true,
  },
};

export const wsWeddingRoutes = {
  [WsWeddingStates.init]: routes.connection.path,
  [WsWeddingStates.selectCollection]: routes.selectRing.path,
  [WsWeddingStates.selectDiamond]: routes.ringPrice.path,
  [WsWeddingStates.showRoom]: routes.ringsPreview.path,
  [WsWeddingStates.payment]: routes.payment.path,
  [WsWeddingStates.selectWitness]: routes.witness.path,
  [WsWeddingStates.ceremony]: routes.ceremony.path,
  [WsWeddingStates.final]: routes.certificate.path,
};
