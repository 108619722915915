import { FC, PropsWithChildren } from 'react';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { Splide } from '@splidejs/react-splide';
import { Options as SplideOptions } from '@splidejs/splide';
import { COLOR_ACCENT, COLOR_LIGHT_GRAY_ALPHA_2, COLOR_LIGTH_VIOLET, COLOR_WHITE } from 'shared/config';
import { BORDER_ACCENT_BOLD } from 'shared/config/theme/common';

type SliderProps = {
  perPage?: number;
  pagination?: boolean;
  gap?: number;
  padding?: string | number | { left: string | number; right: string | number };
  width?: number | string;
  isArrows?: boolean;
  hideArrowsOnMobile?: boolean;
  disableHover?: boolean;
  arrowsMobileTop?: number;
};

export const StyledSlide = styled(Splide)`
  overflow: visible; 

  & .splide__track {
    overflow: visible;
    width: 550px;

    ${({ theme }) => `${theme.breakpoints.down('md')} {
      width: 330px;
      margin-left: -18px;
    }`}
  },

  & .splide__slide {
    display: inline-flex;
    vertical-align: middle;
    visibility: hidden;

    &.is-next, &.is-prev {
      display: inline-flex;
      visibility: inherit;
      opacity: 0.6;
      & > * {
        transform: scale(0.8, 0.8);
      },
    },
    
    &.is-active {
      display: inline-flex;
      visibility: inherit;
      z-index: 1;

      ${({ options }) =>
        !options?.disableHover &&
        `
          cursor: pointer;
          
          &:hover .border-wrapper {
            border: ${BORDER_ACCENT_BOLD};
          },
      `}
    }
  },
`;

export const Slider: FC<PropsWithChildren<SliderProps> & SplideOptions> = ({
  children,
  onMoved,
  onClick,
  arrowsMobileTop,
  ...splideOptions
}) => {
  const theme = useTheme();
  const isDownMdLayout = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        width: { xs: 330, md: 550 },
        '.splide__arrow': {
          background: COLOR_LIGTH_VIOLET,
          width: { xs: 48, md: 60 },
          height: { xs: 48, md: 60 },
          svg: { fill: COLOR_WHITE, width: { xs: '0.7rem', md: '0.9rem' }, height: { xs: '0.7rem', md: '0.9rem' } },
          '&--prev': {
            left: { xs: 110, md: -60 },
            top: { xs: arrowsMobileTop || 386, md: '50%' },
          },
          '&--next': {
            right: { xs: 110, md: -60 },
            top: { xs: arrowsMobileTop || 386, md: '50%' },
          },
        },
        '.splide:not(.is-overflow) .splide__pagination': {
          display: 'flex',
          gap: 0.5,
        },
        '.splide__pagination__page': {
          display: { xs: 'none', md: 'unset' },
          background: COLOR_LIGHT_GRAY_ALPHA_2,
          top: 40,
          width: 14,
          height: 14,
          '&.is-active': {
            background: COLOR_ACCENT,
            transform: 'scale(1, 1)',
          },
        },
      }}
    >
      <StyledSlide
        onMoved={onMoved}
        onClick={onClick}
        options={{
          arrows: true,
          perPage: 3,
          perMove: 1,
          type: 'loop',
          focus: 1,
          gap: isDownMdLayout ? -240 : -240,
          padding: 0,
          updateOnMove: true,
          cloneStatus: false,
          fixedWidth: isDownMdLayout ? 281 : 343,
          drag: false,
          speed: 0,
          ...splideOptions,
        }}
      >
        {children}
      </StyledSlide>
    </Box>
  );
};
