import { routes } from 'app/providers/router/routes';

export enum EngagementType {
  Model = 'role_model',
  Person = 'person',
}
export const EngagementTypePath = {
  [EngagementType.Model]: routes.model.path,
  [EngagementType.Person]: routes.fianceCode.path,
};

export const EngagementTypeSocketParam = {
  [EngagementType.Model]: 'role_model',
  [EngagementType.Person]: 'person',
};
