import { ringActions } from 'entities/ring/model';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { camelize, logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { ringApi } from '../ajax';

export function* getCollectionsSaga({ type }: SagaPayload<typeof ringActions.getCollections>) {
  yield* put(request(type));
  try {
    const data = yield* call(ringApi.getCollections, { collectionType: 'ring' });
    const collections = camelize(data);
    yield* put(ringActions.updateRingState({ collections }));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getCollections', err);
  }
}

export default function* listener() {
  yield takeLatest(ringActions.getCollections.type, getCollectionsSaga);
}
