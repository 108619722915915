import { COLOR_ACCENT, COLOR_DARK, COLOR_STROKE, COLOR_WHITE_ALPHA_5 } from '../colors.constants';

export const colorsRadioAndCheckbox = {
  colorRadioAndCheckboxBackground: COLOR_DARK,
  colorCheckboxBackground: COLOR_WHITE_ALPHA_5,
  colorRadioAndCheckboxBorder: COLOR_STROKE,
  colorRadioAndCheckboxBorderHover: COLOR_STROKE,
  colorRadioAndCheckboxChecked: COLOR_ACCENT,
  colorRadioBackgroundChecked: COLOR_DARK,
};
