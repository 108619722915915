import { ChangeEventHandler } from 'react';
import { TextField } from '@mui/material';
import { FontWeights } from 'shared/config/theme/Typography';

export type CodeTextFieldProps = {
  inputValue: string;
  placeholder?: string;
  disabled: boolean;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};
export const CodeTextField = ({ inputValue, ...props }: CodeTextFieldProps) => {
  return (
    <TextField
      value={inputValue}
      fullWidth
      {...props}
      sx={{
        width: 190,
        '.MuiInputBase-root': {
          px: 2,
          py: 1.25,
          borderRadius: 4,
          '.MuiInputBase-input': { fontSize: '32px', textAlign: 'center', fontWeight: FontWeights.Regular },
        },
      }}
    />
  );
};
