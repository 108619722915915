import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { useWeb3Auth } from 'app/providers/Web3AuthProvider';
import { userActions, userSelector } from 'entities/user';
import { COLOR_WHITE, uiSelector } from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { EngagementType, EngagementTypePath, RequestStatus, UpdateUserRequest } from 'shared/types';
import { checkRequestStatusEquality } from 'shared/utils';
import { useTranslation } from 'react-i18next';

import { connectionDefaultValues, ConnectionFormState, connectionValidationSchema } from '../model';
import { AgreementCheckbox, GenderSelectBlock, WeddingTypeBlock } from '../ui';

interface IConnectionContainerProps {
  partnerPromoCode?: string;
}

export const ConnectionContainer: FC<IConnectionContainerProps> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uiState = useShallowSelector(uiSelector.getUI);
  const [weddingType, setWeddingType] = useState<EngagementType | null>(EngagementType.Person);

  const { isLoading: isWeb3AuthLoading } = useWeb3Auth();
  const { firstName, lastName, sex, email } = useShallowSelector(userSelector.getUser);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ConnectionFormState>({
    resolver: yupResolver(connectionValidationSchema),
    defaultValues: connectionDefaultValues,
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const isUpdateUserInfoSuccess = checkRequestStatusEquality(
    uiState[userActions.updateUserInfo.type],
    RequestStatus.SUCCESS,
  );

  const onSave: SubmitHandler<UpdateUserRequest> = (data) => {
    dispatch(
      userActions.updateUserInfo({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        sex: data.sex,
      }),
    );
  };

  useEffect(() => {
    reset({ email, sex, firstName, lastName, agree: false });
  }, [email, sex, firstName, lastName, reset]);

  const handleNavigate = () => {
    if (weddingType) {
      navigate(EngagementTypePath[weddingType]);
    }
  };

  useEffect(() => {
    if (isUpdateUserInfoSuccess && weddingType) {
      navigate(EngagementTypePath[weddingType]);
      dispatch({ type: `${userActions.updateUserInfo?.type}_RESET` });
    }
    // Reason: navigate only on updateUserInfoSuccess, not on weddingType change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUpdateUserInfoSuccess, navigate]);

  return (
    <>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSave)}
        sx={{ alignItems: 'center', width: '100%', maxWidth: '550px', mx: 'auto', mt: { xs: 5, md: 9 } }}
      >
        <Stack gap={3} width="100%">
          <Stack spacing={4} width="100%">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder={t('Email')}
                  disabled={!!email}
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ''}
                />
              )}
            />
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder={t('Name')}
                  disabled={!!firstName}
                  error={!!errors.firstName}
                  helperText={errors.firstName ? errors.firstName.message : ''}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder={t('FamilyName')}
                  disabled={!!lastName}
                  error={!!errors.lastName}
                  helperText={errors.lastName ? errors.lastName.message : ''}
                />
              )}
            />
          </Stack>
          <GenderSelectBlock control={control} disabled={!!sex} />
          {((!firstName && !lastName) || !sex) && !isWeb3AuthLoading && <AgreementCheckbox control={control} />}
          <WeddingTypeBlock selectedWeddingType={weddingType} setSelectedWeddingType={(wt) => setWeddingType(wt)} />
        </Stack>

        {(!firstName && !lastName) || !sex ? (
          <Button
            type="submit"
            variant="outlined"
            disabled={isSubmitting || !isValid || isWeb3AuthLoading || !weddingType}
            sx={{ mt: 5, width: 'auto' }}
          >
            {isSubmitting || isWeb3AuthLoading ? (
              <CircularProgress size={30} sx={{ color: COLOR_WHITE }} />
            ) : (
                t('GetMarried')
            )}
          </Button>
        ) : (
          <Button variant="outlined" disabled={!weddingType} onClick={handleNavigate} sx={{ mt: 5, width: 'auto' }}>
            {t('GetMarried')}
          </Button>
        )}
      </Stack>

      {/* {firstName && lastName && promoCode && sex && ( */}
    </>
  );
};
