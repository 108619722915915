import { api } from 'shared/api/axios';

import { SelectNftModelReq } from '../types';

const getUserNftModels = () => {
  return api({
    url: `/nft_model`,
    method: 'GET',
  });
};
const selectNftModel = ({ nftModelId }: SelectNftModelReq) => {
  return api({
    url: `/nft_model?nft_model_id=${nftModelId}`,
    method: 'POST',
  });
};

export const modelApi = {
  getUserNftModels,
  selectNftModel,
};
