import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'app/store';
import { SendEngagementInitationReq } from 'entities/wedding';
import { FeedbackRequest, SexType, UpdateUserRequest } from 'shared/types';

export type UserState = {
  address: string;
  email: string;
  firstName: string;
  id: string | undefined;
  isRegistered: boolean;
  lastName: string;
  profileImage: string;
  promoCode: string;
  referralCode: string;
  sex: SexType | undefined;
  evmAddress: string;
  privateKey: string;
  isWitness: boolean;
  proposalTimeout: number;
};

export const initialState: UserState = {
  email: '',
  id: undefined,
  firstName: '',
  lastName: '',
  sex: undefined,
  isRegistered: false,
  profileImage: '',
  address: '',
  promoCode: '',
  referralCode: '',
  evmAddress: '',
  privateKey: '',
  isWitness: false,
  proposalTimeout: 0,
};

export const userSelector = {
  getUser: (state: State): UserState => state.user,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserState: (state, action: PayloadAction<Partial<UserState>>) => ({
      ...state,
      ...action.payload,
    }),

    clearUserState: () => ({
      ...initialState,
    }),

    /** For saga */
    sendFeedback(state, action: PayloadAction<FeedbackRequest>) {},
    getUserInfo(state, action: PayloadAction) {},
    updateUserInfo(state, action: PayloadAction<UpdateUserRequest>) {},
    sendEngagementInvitation(state, action: PayloadAction<SendEngagementInitationReq>) {},
  },
});

export const { reducer: userReducer } = userSlice;
export const { actions: userActions } = userSlice;
