import { Box, Stack, styled, Typography } from '@mui/material';
import { OpenseaIcon } from 'shared/assets';
import { BORDER_MAIN_BOLD, BORDER_RADIUS_XXL, COLOR_DARK } from 'shared/config';

const BorderWrapper = styled(Box)(() => ({
  border: BORDER_MAIN_BOLD,
  borderRadius: BORDER_RADIUS_XXL,
  overflow: 'hidden',
  background: COLOR_DARK,
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  width: 344,
  height: 313,
  img: { width: '100%', height: '100%', objectFit: 'cover' },
  [theme.breakpoints.down('md')]: {
    width: 277,
  },
}));

const StyledInfo = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: theme.spacing(1.75, 2),
  img: {
    width: 40,
    height: 40,
  },
}));

export type ModelCardProps = {
  nickname: string;
  imageUrl: string;
};

export const ModelCard = ({ nickname, imageUrl }: ModelCardProps) => {
  return (
    <BorderWrapper>
      <ImageWrapper>
        <img src={imageUrl} alt={nickname} />
      </ImageWrapper>
      <StyledInfo>
        <Typography lineHeight="30px" fontSize={30} className="accent secondary">
          {nickname}
        </Typography>
        <img src={OpenseaIcon} alt="opensea" />
      </StyledInfo>
    </BorderWrapper>
  );
};
