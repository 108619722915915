import { toast } from 'react-toastify';
import { coupleActions } from 'entities/couple/model';
import { userSelector } from 'entities/user/model';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { camelize, logger } from 'shared/utils';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { coupleApi } from '../ajax';

export function* addPartnerSaga({ type, payload }: SagaPayload<typeof coupleActions.addPartner>) {
  yield* put(request(type));
  const { promoCode } = payload;
  try {
    const { id: currentUserId } = yield select(userSelector.getUser);
    const data = yield* call(coupleApi.addPartner, promoCode);
    const { user, partner, ...coupleData } = camelize(data);

    let partnerData;
    let userData;

    if (user.id?.toLowerCase() === currentUserId?.toLowerCase()) {
      partnerData = partner;
      userData = user;
    } else {
      partnerData = user;
      userData = partner;
    }

    yield* put(coupleActions.updateCoupleState({ partner: partnerData, user: userData, ...coupleData }));
    yield* put(success(type));
  } catch (err: any) {
    yield* put(error(type, err));
    logger('addPartner', err);
    toast.error(err?.data?.detail);
  }
}

export default function* listener() {
  yield takeLatest(coupleActions.addPartner.type, addPartnerSaga);
}
