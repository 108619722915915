import { coupleSagas } from 'entities/couple';
import { modelSagas } from 'entities/models';
import { ringSagas } from 'entities/ring';
import { userSagas } from 'entities/user';
import { weddingSagas } from 'entities/wedding';
import { witnessSagas } from 'entities/witness';
import { all, call } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([userSagas, coupleSagas, modelSagas, weddingSagas, ringSagas, witnessSagas].map(call));
}
