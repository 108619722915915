import { FC, FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal as MuiModal } from '@mui/material';

import { IModal, IModalPayload, modalActions, modalSelector, ModalType } from '../model';

import { ConnectWallet } from './ConnectWallet';
import { InviteWitnessModal } from './InviteWitness';
import { LoadingModal } from './LoadingModal';
import { MoonpayModal } from './MoonpayModal';
import { TellAboutYourself } from './TellAboutYourself';

const { closeModal } = modalActions;
const modalMapping: { [key in keyof typeof ModalType]: FunctionComponent<IModal> } = {
  [ModalType.ConnectWallet]: ConnectWallet,
  [ModalType.Loading]: LoadingModal,
  [ModalType.TellAboutYourself]: TellAboutYourself,
  [ModalType.InviteWitness]: InviteWitnessModal,
  [ModalType.Moonpay]: MoonpayModal,
};

export const Modal: FC<{ type: ModalType; data: IModalPayload['data'] }> = ({ type, data }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => e.key === 'Escape' && dispatch(closeModal());
    if (type) {
      window.addEventListener('keyup', handleEscape);
    }
    return () => window.removeEventListener('keyup', handleEscape);
  }, [dispatch, type]);

  const ModalComponent = modalMapping[type];

  return (
    <MuiModal
      open={Boolean(type)}
      onClose={() => {
        dispatch(closeModal());
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalComponent onClose={closeModal} data={data} />
    </MuiModal>
  );
};

export const ModalContainer = () => {
  const { modal } = useSelector(modalSelector);
  if (!modal) return null;
  return <Modal type={modal.type} data={modal.data} />;
};
