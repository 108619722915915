import { toast } from 'react-toastify';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { camelize, logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { modelApi } from '../ajax';
import { modelActions } from '../model';

export function* getUserNftModelsSaga({ type }: SagaPayload<typeof modelActions.getUserNftModels>) {
  yield* put(request(type));
  try {
    const data = yield* call(modelApi.getUserNftModels);

    yield* put(modelActions.updateModel({ userModels: camelize(data) }));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getUserNftModels', err);
    toast.error('Something went wrong');
  }
}

export default function* listener() {
  yield takeLatest(modelActions.getUserNftModels.type, getUserNftModelsSaga);
}
