import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { userActions, userSelector } from 'entities/user';
import { Web3AuthConnectForm } from 'entities/web3auth-connect';
import { witnessActions } from 'entities/witness';
import { WitnessConnectionForm } from 'features/wintess-connect';
import { useShallowSelector } from 'shared/hooks';
import { EngagementType } from 'shared/types';

export const WitnessConnection = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { id: userId, firstName } = useShallowSelector(userSelector.getUser);
  const engagementId = searchParams.get('engagementId') || '';
  const engagementType = (searchParams.get('engagementType') || '') as EngagementType;
  const invitationCode = searchParams.get('invitationCode') || '';

  useEffect(() => {
    if (firstName) {
      dispatch(userActions.updateUserState({ isWitness: true }));
      dispatch(witnessActions.connectWitness({ engagementId, invitationCode, engagementType }));
    }
  }, [dispatch, engagementId, invitationCode, engagementType, firstName]);

  return (
    <Stack spacing={{ xs: 4, md: 7.5 }} width="100%" alignItems="center" mt={{ xs: 2, md: 8 }}>
      <Typography variant="h3">Witness registration</Typography>
      {!userId ? <Web3AuthConnectForm /> : <WitnessConnectionForm />}
    </Stack>
  );
};
