import { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import copy from 'copy-to-clipboard';
import { COLOR_STROKE } from 'shared/config/theme/colors';
import { CopyIcon } from 'shared/ui/components';
import { shortenPhrase } from 'shared/utils';

import { CopyButtonField } from './CopyButtonField';

type CopyAddressProps = {
  text: string;
};
export const CopyAddress = ({ text }: CopyAddressProps) => {
  const [helperText, setHelperText] = useState(text);
  const theme = useTheme();
  const isDownSmLayout = useMediaQuery(theme.breakpoints.down('sm'));
  const handleCopyText = () => {
    copy(text);
    setHelperText('Copied!');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setHelperText(text);
    }, 1500);
    return () => clearTimeout(timer);
  }, [text, helperText]);

  return (
    <CopyButtonField onClick={handleCopyText} disabled={!text}>
      {isDownSmLayout ? shortenPhrase(helperText, 14, 13) : shortenPhrase(helperText, 11, 9)}
      <CopyIcon sx={{ width: 24, height: 24, path: { fill: COLOR_STROKE }, position: 'absolute', right: '8px' }} />
    </CopyButtonField>
  );
};
