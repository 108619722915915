/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SafeEventEmitterProvider } from '@web3auth/base';
import Web3 from 'web3';

export class Web3Service {
  public polygonProvider: SafeEventEmitterProvider;

  public polygonWeb3: any = '';

  constructor(polygonProvider: SafeEventEmitterProvider) {
    this.polygonProvider = polygonProvider;
    this.polygonWeb3 = new Web3();
  }

  public PolygonWeb3(): Web3 {
    this.polygonWeb3.setProvider(this.polygonProvider as any);
    return new Web3(this.polygonProvider as any);
  }

  async getPolygonAddress(): Promise<any> {
    try {
      const web3 = new Web3(this.polygonProvider as any);
      const userAddress = await web3.eth.getAccounts();
      // Get user's Ethereum public address
      return userAddress[0];
    } catch (error) {
      return error;
    }
  }

  async getPrivateKey(): Promise<any> {
    try {
      if (typeof this.polygonProvider !== 'string') {
        return await this.polygonProvider.request({
          method: 'eth_private_key',
        });
      }
      return null;
    } catch (error) {
      return error as string;
    }
  }
}
