import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: '24px',
      height: '24px',
      color: 'transparent',
    }}
  >
    <path
      d="M5 12H19M5 12L11 6M5 12L11 18"
      stroke="#2DF6DE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
