import {
  COLOR_ACCENT,
  COLOR_LIGHT_GRAY_ALPHA_2,
  COLOR_WHITE_ALPHA_14,
  COLOR_WHITE_ALPHA_21,
} from '../colors.constants';

export const colorsIconButton = {
  // outlined button theme
  colorIconButtonOutlinedBackground: 'transparent',
  colorIconButtonOutlinedBackgroundHover: COLOR_ACCENT,
  colorIconButtonOutlinedBorder: `2px solid ${COLOR_ACCENT}`,

  colorIconButtonContainedBackground: COLOR_LIGHT_GRAY_ALPHA_2,

  colorIconButtonTertiaryBorder: COLOR_WHITE_ALPHA_21,
  colorIconButtonTertiaryBackground: COLOR_WHITE_ALPHA_14,
};
