/* eslint-disable camelcase */
/* eslint-disable no-console */
import { createContext, FunctionComponent, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify';
import { CustomChainConfig, WALLET_ADAPTER_TYPE } from '@web3auth/base';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
// import { Web3AuthCore } from '@web3auth/core';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { LOGIN_PROVIDER_TYPE, OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { AxiosError } from 'axios';
import { coupleActions } from 'entities/couple';
import { userActions, userApi, UserState } from 'entities/user';
import { weddingActions } from 'entities/wedding';
import Cookies from 'js-cookie';
import { closeSocket, socket } from 'shared/api';
import { EVM_CHAIN_CONFIG, EVM_CHAIN_CONFIG_TYPE, WEB3AUTH_NETWORK_TYPE } from 'shared/config';
import { CLIENT_ID, isMainnet, SESSION_TIME } from 'shared/config/constants';
import { EvmChains, Nullable } from 'shared/types';
import { UpdateUserRequest } from 'shared/types/api/UpdateUserRequest';
import { Web3Service } from 'shared/web3';
import { isExpired, decodeToken } from "react-jwt";

export interface IWeb3AuthContext {
  web3Auth: Web3AuthNoModal | null;
  web3Service: Web3Service | null;
  isLoading: boolean;
  login: (adapter: WALLET_ADAPTER_TYPE, provider: LOGIN_PROVIDER_TYPE, login_hint?: string) => Promise<void>;
  logout: () => Promise<Nullable<void>>;
  getUserInfo: () => Promise<Nullable<void>>;
  patchUserInfo: (data: UpdateUserRequest) => Promise<void>;
}

export const Web3AuthContext = createContext<IWeb3AuthContext>({} as IWeb3AuthContext);

export function useWeb3Auth() {
  return useContext(Web3AuthContext);
}

interface IWeb3AuthState {
  web3AuthNetwork: WEB3AUTH_NETWORK_TYPE;
  chain: EVM_CHAIN_CONFIG_TYPE;
  children?: ReactNode;
}
interface IWeb3AuthProps {
  children?: ReactNode;
  web3AuthNetwork: WEB3AUTH_NETWORK_TYPE;
  chain: EVM_CHAIN_CONFIG_TYPE;
}

export const Web3AuthProvider: FunctionComponent<IWeb3AuthState> = ({
  children,
  web3AuthNetwork,
  chain,
}: IWeb3AuthProps) => {
  const dispatch = useDispatch();
  const [web3Auth, setWeb3Auth] = useState<Web3AuthNoModal | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [web3Service, setWeb3Service] = useState<Web3Service | null>(null);

  const updateUser = (data: Partial<UserState>) => {
    dispatch(userActions.updateUserState(data));
  };

  const getTokenExpirationTime = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );
    const { exp } = JSON.parse(jsonPayload);
    return exp;
  };

  const getUserInfo = useCallback(
    async (web3AuthInstance?: Web3AuthNoModal) => {
      if (!web3AuthInstance && !web3Auth) {
        console.log('web3auth not initialized yet');
        return;
      }
      const instance = web3Auth || web3AuthInstance;

      try {
        const { idToken } = await instance!.authenticateUser();

        console.log(web3AuthInstance)
        console.log(instance!.authenticateUser())
        console.log(instance!.getUserInfo())
        const tokenExpirationTime = getTokenExpirationTime(idToken || '');
        const tokenMaxAge = ((tokenExpirationTime * 1000 - Date.now()) / 1000).toFixed(0);
        Cookies.set('token', idToken || '', { expires: +tokenMaxAge });
        localStorage.setItem('token', idToken || '');
        console.log(decodeToken(idToken)); // Выведет декодированный токен

        const userInfoFromBackend = await userApi.getUser();



        updateUser({ ...userInfoFromBackend });
        dispatch(coupleActions.getCouple());

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log('GET USER ERROR', error);
        Cookies.remove('token');
        dispatch(userActions.clearUserState());
      }
    },
    [dispatch, updateUser],
  );

  const patchUserInfo = async (data: UpdateUserRequest) => {
    try {
      const userInfoResponse = await userApi.updateUser(data);
      toast.success('You have successfully update your profile info');
      updateUser({ ...userInfoResponse });
    } catch (err) {
      if (err instanceof AxiosError) console.error('patchUserInfo error:', err.message);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateNetwork = useCallback(
    async (provider: any) => {
      const evmService = new Web3Service(provider);
      const evmAddress: string = await evmService.getPolygonAddress();
      const privateKey = await evmService.getPrivateKey();
      evmService.PolygonWeb3();
      setWeb3Service(evmService);
      dispatch(userActions.updateUserState({ evmAddress, privateKey }));
    },
    [dispatch],
  );

  const login = async (adapter: WALLET_ADAPTER_TYPE, loginProvider: LOGIN_PROVIDER_TYPE, login_hint?: string) => {
    try {
      setIsLoading(true);
      if (!web3Auth) {
        console.log('web3auth not initialized yet');
        return;
      }
      let localProvider;
      if (loginProvider == 'jwt') {
        localProvider = await web3Auth.connectTo(adapter, {
          loginProvider: 'yandex',
          login_hint,
          mfaLevel: 'none',
          sessionTime: SESSION_TIME,
          extraLoginOptions: {
            max_age: SESSION_TIME,
            domain: "https://dev-ue6detajcn2pot8j.us.auth0.com", // Please append "https://" before your domain
            verifierIdField: "sub",
          },
        });
      } else {
        localProvider = await web3Auth.connectTo(adapter, {
          loginProvider,
          login_hint,
          mfaLevel: 'none',
          sessionTime: SESSION_TIME,
          extraLoginOptions: {
            max_age: SESSION_TIME,
          },
        });
      }


      await updateNetwork(localProvider);
      if (window.screen.width > 600) {
        toast.success('Successfully signed in');
      }

      await getUserInfo();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error('Something went wrong, reload the page');
      toast.error(error);
      console.log('login web3auth', error);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    if (!web3Auth) {
      console.log('web3auth not initialized yet');
      return;
    }
    await web3Auth.logout();
    dispatch(userActions.clearUserState());
    dispatch(weddingActions.clearWeddingState());
    dispatch(coupleActions.clearCoupleState());
    // Reason: close socket only after clear user state, cause onclose in app create reconnect if uderId present
    if (socket) {
      setTimeout(closeSocket, 0);
    }
    localStorage.removeItem('isShowCompleteProfileModal');
    Cookies.remove('token');
  };

  const init = useCallback(
    async (chainConfig: CustomChainConfig) => {
      try {
        setIsLoading(true);
        const web3AuthInstance = new Web3AuthNoModal({
          chainConfig,
          enableLogging: true,
          clientId: CLIENT_ID,
          sessionTime: SESSION_TIME,
        });
        const privateKeyProvider = new EthereumPrivateKeyProvider({
          config: { chainConfig },
        });

        const adapter = new OpenloginAdapter({
          adapterSettings: {
            network: web3AuthNetwork,
            clientId: CLIENT_ID,
            uxMode: window.screen.width < 600 ? 'redirect' : 'popup',
            loginConfig: {
              vkontakte: {
                verifier: "vkontakte",
                typeOfLogin: "jwt",
                clientId: "oTL3N8z9hyIH2txDwsOP4dUb76GQxbSm",
                jwtParameters: {
                  domain: "https://dev-ue6detajcn2pot8j.us.auth0.com", // Auth0 domain
                  verifierIdField: "sub",
                },
              },
              yandex: {
                verifier: "tkey-yandex",
                typeOfLogin: "jwt",
                clientId: "YYbm03sQBJwZBrF4koCMStsjtVDWW5E1",
                jwtParameters: {
                  domain: "https://dev-ue6detajcn2pot8j.us.auth0.com", // Auth0 domain
                  verifierIdField: "sub",
                },
              },
            },
          },
          privateKeyProvider,
        });

        web3AuthInstance.configureAdapter(adapter);

        await web3AuthInstance.init();
        setWeb3Auth(web3AuthInstance);

        if (web3AuthInstance.provider) {
          await updateNetwork(web3AuthInstance.provider);
        }

        await getUserInfo(web3AuthInstance);
      } catch (error) {
        console.error('init web3auth', error);
      } finally {
        setIsLoading(false);
      }
    },
    [getUserInfo, updateNetwork, web3AuthNetwork],
  );

  useEffect(() => {
    const currentChainConfig = EVM_CHAIN_CONFIG[chain as EvmChains][isMainnet ? 'mainnet' : 'testnet'];
    init(currentChainConfig).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only when first render

  const contextProvider = {
    web3Auth,
    web3Service,
    isLoading,
    login,
    logout,
    getUserInfo,
    patchUserInfo,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
