import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CurvedArrowIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: '24px',
      height: '24px',
      color: 'transparent',
    }}
  >
    <path
      d="M20 16L9 16C6.25 16 4 13.802 4 11.115V11.115L4 11.145L4 6"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.952 11.9521L20 16.0001L15.952 20.0481"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
