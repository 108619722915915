import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Box, Button, CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Message } from 'entities/message';
import { modalActions, ModalType } from 'entities/modal';
import { RingWithUser } from 'entities/ring-with-user';
import { UserRole, userSelector, UserStatus } from 'entities/user';
import { weddingActions, weddingSelector } from 'entities/wedding';
import { COLOR_VIOLET, COLOR_WHITE, uiSelector } from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { EngagementType, SexType } from 'shared/types';
import { Typography } from 'shared/ui/components';
import { checkRequestStatusEquality } from 'shared/utils';
import { useTranslation } from 'react-i18next';

enum CeremonySteps {
  WitnessTestify = 1,
  FeemaleTestify,
  MaleTestify,
  RingsMint,
}

export const GodWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: -1,
  height: 600,
  top: '-75px',
  img: {
    height: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    height: 300,
    top: '-30px',
  },
}));

export const Ceremony = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDownLgLayout = useMediaQuery(theme.breakpoints.down('lg'));
  const [step, setStep] = useState(1);

  const uiState = useShallowSelector(uiSelector.getUI);
  const { members, witness, godWitness, type } = useShallowSelector(weddingSelector.getWedding);
  const { id: userId, sex: userSex } = useShallowSelector(userSelector.getUser);
  const isCurrentUserMan = userSex === SexType.man;
  const isCurrentUserWitness = userId === witness?.user.id;
  const isFirstMemberWoman = members[0]?.user.sex === SexType.woman;
  const isWomanTestified = isFirstMemberWoman ? members[0]?.isTestified : members[1]?.isTestified;
  const isManTestified = !isFirstMemberWoman ? members[0]?.isTestified : members[1]?.isTestified;
  const isWitnessTestified = godWitness ? true : witness?.isTestified;
  const messageOrientation = step === 2 ? 'right' : 'left';
  const firstMemberDisplayData = {
    status: members[0]?.user.isOnline ? UserStatus.Online : UserStatus.Offline,
    firstName: members[0]?.user.firstName || '',
    lastName: members[0]?.user.lastName || '',
    avatar: members[0]?.user.profileImage,
    ringImg: members[0]?.token?.meta?.imageUrl || '',
  };

  const secondMemberDisplayData = {
    status: members[1]?.user.isOnline ? UserStatus.Online : UserStatus.Offline,
    firstName: members[1]?.user.firstName || '',
    lastName: members[1]?.user.lastName || '',
    avatar: members[1]?.user.profileImage,
    ringImg: members[1]?.token?.meta?.imageUrl || '',
  };

  const femaleDisplayData = isFirstMemberWoman ? firstMemberDisplayData : secondMemberDisplayData;
  const manDisplayData = !isFirstMemberWoman ? firstMemberDisplayData : secondMemberDisplayData;

  useEffect(() => {
    if (type === EngagementType.Model) {
      if (isManTestified || isWomanTestified) {
        setStep(CeremonySteps.RingsMint);
      } else if (isCurrentUserMan && isWitnessTestified) {
        setStep(CeremonySteps.MaleTestify);
      } else if (!isCurrentUserMan && isWitnessTestified) {
        setStep(CeremonySteps.FeemaleTestify);
      }
    } else if (isManTestified) {
      setStep(CeremonySteps.RingsMint);
    } else if (isWomanTestified) {
      setStep(CeremonySteps.MaleTestify);
    } else if (isWitnessTestified) {
      setStep(CeremonySteps.FeemaleTestify);
    }
  }, [isCurrentUserMan, isManTestified, isWitnessTestified, isWomanTestified, type]);

  const isTestifyLoading = checkRequestStatusEquality(uiState[weddingActions.ceremonyTestify?.type]);

  const handleDeleteWitness = () => {
    dispatch(weddingActions.deleteWitness());
  };
  const handleButtonClick = () => {
    dispatch(weddingActions.ceremonyTestify());
  };

  useEffect(() => {
    if (step === 4) {
      dispatch(
        modalActions.openModal({
          type: ModalType.Loading,
          data: {
            title: t('ceremony_1'),
            text: t('ceremony_2'),
          },
        }),
      );
      setTimeout(() => {
        dispatch(
          modalActions.openModal({
            type: ModalType.Loading,
            data: {
              title: t('ceremony_3'),
              text: t('ceremony_2'),
            },
          }),
        );
      }, 5000);
    }
    return () => {
      dispatch(modalActions.closeModal());
    };
  }, [step, dispatch]);

  return (
    <Grid container spacing={3} justifyContent="space-between">
      <Grid item xs={12} mb={{ xs: 1, sm: godWitness ? 1 : 10 }}>
        <Typography variant="h3" className="center" mt={4}>
          {t('ceremony_4')}
          <br />
          {t('ceremony_5')}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={step === 1 ? 4 : 6} lg={step === 4 ? 6 : 4} order={{ xs: 2, sm: step === 1 ? 1 : 2 }}>
        <RingWithUser
          ringProps={{ img: femaleDisplayData.ringImg }}
          userProps={{ ...femaleDisplayData, role: UserRole.Female, withFullName: true }}
          active={step === 3}
        />
      </Grid>
      {step !== 1 && (
        <>
          <Grid item container order={{ xs: 4, lg: 5 }} justifyContent="center" maxWidth={{ xs: 178, md: 264 }}>
            {(step !== 2 || (step === 2 && !isCurrentUserMan && !isCurrentUserWitness)) && (
              <Button
                variant="contained"
                className={
                  step === 2 && !isCurrentUserMan && !isCurrentUserWitness
                    ? 'active-bg-disabled'
                    : 'active-text-disabled'
                }
                disabled={isCurrentUserWitness || (step === 2 && isCurrentUserMan) || step !== 2}
                sx={{ width: 154, textTransform: 'none' }}
                onClick={handleButtonClick}
              >
                &quot;{t('ceremony_6')}&quot;
              </Button>
            )}
          </Grid>
          {(step === 4 || (step === 3 && isCurrentUserMan && !isCurrentUserWitness)) && (
            <Grid
              item
              container
              order={{ xs: 6, lg: 5 }}
              justifyContent="center"
              ml="auto"
              maxWidth={{ xs: 178, md: 264 }}
            >
              <Button
                variant="contained"
                className={
                  step === 3 && isCurrentUserMan && !isCurrentUserWitness
                    ? 'active-bg-disabled'
                    : 'active-text-disabled'
                }
                disabled={isCurrentUserWitness || (step === 3 && !isCurrentUserMan) || step === 4}
                sx={{ width: 154, textTransform: 'none' }}
                onClick={handleButtonClick}
              >
                &quot;{t('ceremony_6')}&quot;
              </Button>
            </Grid>
          )}
        </>
      )}
      <Grid
        item
        container
        xs={12}
        sm={step === 1 ? 4 : 12}
        order={{ xs: 1, sm: step === 1 ? 2 : 1 }}
        direction="column"
        alignItems="center"
        gap={2}
        position="relative"
        minHeight={150}
      >
        {!godWitness ? (
          <>
            <Typography className="center">
              {witness?.user ? (
                <>
                  Witness:{' '}
                  <Typography
                    component="span"
                    className="semi-bold"
                  >{`${witness?.user?.firstName} ${witness?.user?.lastName}`}</Typography>
                </>
              ) : (
                  t('ceremony_7')
              )}
            </Typography>
            <Avatar
              src={witness?.user.profileImage}
              sx={{ width: { xs: 102, sm: 136 }, height: { xs: 102, sm: 136 }, bgcolor: COLOR_VIOLET }}
            >
              {!witness ? (
                <CircularProgress sx={{ color: COLOR_WHITE, width: 56, height: 56 }} />
              ) : (
                <Typography variant="h2" className="white regular primary">{`${witness.user.firstName?.charAt(
                  0,
                )}${witness.user.lastName?.charAt(0)}`}</Typography>
              )}
            </Avatar>
            {step === 1 && !isCurrentUserWitness && (
              <Button variant="text" className="blue underline" onClick={handleDeleteWitness}>
                {t('ceremony_8')}
              </Button>
            )}
          </>
        ) : (
          <GodWrapper>
            <img src={godWitness.imageUrl} alt={godWitness.name} />
          </GodWrapper>
        )}
        {((isCurrentUserWitness && step === 1) || step !== 1) && (
          <Button
            className={step === 1 ? 'active-bg-disabled' : 'active-text-disabled'}
            variant="contained"
            onClick={handleButtonClick}
            disabled={step !== 1 || !isCurrentUserWitness}
            sx={{
              textTransform: 'unset',
            }}
          >
            &quot;{t('ceremony_9')}&quot;
          </Button>
        )}
      </Grid>
      {(step === 2 || step === 3) && (
        <Grid
          item
          container
          // xs="auto"
          lg={4}
          xs={step === 3 ? 12 : 'auto'}
          order={{ xs: step === 2 ? 5 : 7, lg: 3 }}
          justifyContent={step === 2 ? 'end' : 'start'}
          alignItems={step === 2 ? 'end' : 'start'}
          mx={{ xs: 0, md: 5, lg: 0 }}
        >
          <Message
            orientation={isDownLgLayout ? 'top' : messageOrientation}
            text={t('ceremony_10')}
          />
        </Grid>
      )}
      <Grid
        item
        container
        xs={6}
        sm={step === 1 ? 4 : 6}
        lg={step === 4 ? 6 : 4}
        justifyContent="end"
        order={{ xs: 3, lg: 3 }}
      >
        <RingWithUser
          ringProps={{ img: manDisplayData.ringImg }}
          userProps={{ ...manDisplayData, role: UserRole.Man, withFullName: true }}
          active={step === 2}
        />
      </Grid>
    </Grid>
  );
};
