import { toast } from 'react-toastify';
import { weddingActions } from 'entities/wedding';
import { error, request, socket, success } from 'shared/api';
import { Emits } from 'shared/types';
import { SagaPayload } from 'shared/types/globals';
import { logger, snakeize } from 'shared/utils';
import { put, takeLatest } from 'typed-redux-saga';

export function* selectDiamondSaga({ type, payload }: SagaPayload<typeof weddingActions.selectDiamond>) {
  yield* put(request(type));
  const { wbtcAmountUsd } = payload;
  try {
    const priceData = { emit: Emits.DiamondAmount, data: { wbtcAmountUsd } };
    socket?.send(JSON.stringify(snakeize(priceData)));

    const nextData = { emit: Emits.DiamondNext };
    socket?.send(JSON.stringify(snakeize(nextData)));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    toast.error('Something went wrong');
    logger(weddingActions.selectDiamond.type, err);
  }
}

export default function* listener() {
  yield takeLatest(weddingActions.selectDiamond.type, selectDiamondSaga);
}
