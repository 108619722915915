export const isMainnet = true;
export const CLIENT_ID = isMainnet
  ? 'BM1oCLkcOWTB_ElHb8Z24KYxTs__sQdI8UyEX6Rdkgd4b3itIMaut3OsVLXZxl8J6UtMD9QmUBP7oit0ytQHFjU'
  : 'BEPXi8ug6TJ4Zz3mQaiYJyxmSnncRLPvmMIJgh1p6BRu5WCqkDqxaJu1WGy4Kn4wt_AcmDfArq5jgFPCThBaLsg';
export const BACKEND_URL = isMainnet ? 'https://web3wed.io/api/' : 'https://wedding-dapp.rocknblock.io/api/';
export const POLYGON_CHAIN_ID = isMainnet ? '137' : '80001';
export const SESSION_TIME = 60 * 60 * 24;
export const CHAINS = [
  {
    name: 'Polygon',
    chainId: isMainnet ? '137' : '80001',
    id: 1,
    type: 'eth',
  },
];
