import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Link, Stack, styled, Typography, List, ListItem, ListItemText,ImageList, ImageListItem, Tooltip } from '@mui/material';
import { routes, wsWeddingRoutes } from 'app/providers/router/routes';
import { modalActions, ModalType } from 'entities/modal';
import { userSelector } from 'entities/user';
import { weddingSelector } from 'entities/wedding';
import { BORDER_RADIUS_XXL, COLOR_ACCENT } from 'shared/config';
import { FontWeights } from 'shared/config/theme/Typography';
import { useShallowSelector } from 'shared/hooks';
import {marriedGX, imageList1, imageList2 ,imageList3} from "../../../../shared/assets";
import { useTranslation } from 'react-i18next';

const LINKS = [
  {
    url: 'https://web3wed.gitbook.io/web3wed/litepaper/how-can-i-get-married-with-web3wed.io',
    text: 'How to get married?',
  },
  {
    url: 'https://web3wed.gitbook.io/web3wed/litepaper/divorce',
    text: 'How to divorce?',
  },
  {
    url: 'https://web3wed.gitbook.io/web3wed/help/how-to-earn-with-web3wed',
    text: 'How I can earn on this?',
  },
];

const imageList = [
  {
    image: imageList1,
    hint: 'The way to prove your love is real and recognized digitally.',
  },
  {
    image: imageList2,
    hint: 'The way to prove your love is real and recognized digitally.',
  },
  {
    image: imageList3,
    hint: 'The way to prove your love is real and recognized digitally.',
  },
];

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  lineHeight: '43px',
  fontWeight: FontWeights.Medium,

  [theme.breakpoints.down('md')]: {
    fontSize: 18,
    lineHeight: '30px',
    fontWeight: FontWeights.Regular,
  },
}));

export const AboutUs = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: userId } = useShallowSelector(userSelector.getUser);
  const { state } = useShallowSelector(weddingSelector.getWedding);

  const handleGetMarriedClick = () => {
    if (!userId) {
      dispatch(
        modalActions.openModal({
          type: ModalType.ConnectWallet,
        }),
      );
    } else {
      navigate(state ? wsWeddingRoutes[state] : routes.connection.path);
    }
  };

  return (
    <>
        <Typography className="center" variant="h2">

            {t('main_AboutUs_p1')}
        </Typography>
        <Typography mt={2.75} className="center" maxWidth={{ xs: '100%', sm: 800 }}>

            {t('main_AboutUs_d1')}
            <Box component="img" src={marriedGX} width={"100%"}/>

            {t('main_AboutUs_d1_1')}
        </Typography>
        <Typography className="center" variant="h2" mt={9.5}>

            {t('main_AboutUs_p2')}
        </Typography>
        <Typography mt={2.75} className="center" maxWidth={{ xs: '100%', sm: 800 }}>
            <ImageList sx={{ width: 200 }} cols={3} className="center"  rowHeight={50}>
                {imageList.map((item) => (
                    <ImageListItem key={item.hint}>
                        <Tooltip title={item.hint}>
                            <Box component="img" src={item.image} width={50}/>
                        </Tooltip>
                    </ImageListItem>
                ))}
            </ImageList>
        </Typography>
        <Typography className="center" variant="h2" mt={9.5}>
            {t('main_AboutUs_p3')}
        </Typography>
        <Typography mt={2.75} className="left">
            <ol>
                <li>{t('main_AboutUs_d3_1')}</li>
                <li>{t('main_AboutUs_d3_2')}</li>
                <li>{t('main_AboutUs_d3_3')}</li>
                <li>{t('main_AboutUs_d3_4')}</li>
                <li>{t('main_AboutUs_d3_5')}</li>
            </ol>
            {t('main_AboutUs_d3_6')}
        </Typography>
        <Typography className="center" variant="h2" mt={9.5}>
            {t('main_AboutUs_p4')}
        </Typography>
        <Typography mt={2.75} className="left" maxWidth={{ xs: '100%', sm: 800 }}>
            <List>
                <ListItem alignItems={"flex-start"} sx={{
                    marginBottom: 2
                }}>
                    <ListItemText>
                        <span style={{color: "#2DF6DE"}}> {t('main_AboutUs_d4_1_span')}</span> {t('main_AboutUs_d4_1')}
                    </ListItemText>
                </ListItem>
                <ListItem sx={{
                    marginBottom: 2
                }}>
                    <ListItemText>
                        <span style={{color: "#2DF6DE"}}> {t('main_AboutUs_d4_2_span')}</span> {t('main_AboutUs_d4_2')}
                    </ListItemText>
                </ListItem>
                <ListItem sx={{
                    marginBottom: 2
                }}>
                    <ListItemText>
                        <span style={{color: "#2DF6DE"}}> {t('main_AboutUs_d4_3_span')}</span> {t('main_AboutUs_d4_3')}
                    </ListItemText>
                </ListItem>
            </List>
        </Typography>
        {/*<Typography className="center" variant="h2">
        Do you love?
      </Typography>
      <StyledTypography mt={2.75} className="center">
        just get your rings, choose you witness & press I do
        <br />
        ps. or even add a Bitcoin to your spouse ring ;)
      </StyledTypography>
      <Button variant="contained" sx={{ marginTop: 3 }} onClick={handleGetMarriedClick}>
        Get married
      </Button>*/}
      <Typography className="center" variant="h2" mt={9.5}>
          {t('main_AboutUs_p5')}
      </Typography>
      <Typography mt={2.75} className="center">
          {t('main_AboutUs_d5')}
      </Typography>
      <Stack
        mt={3}
        alignItems="center"
        sx={{
          width: '100%',
          iframe: {
            border: 'none',
            borderRadius: BORDER_RADIUS_XXL,
            width: '100%',
            maxWidth: '899px',
            height: { xs: 300, sm: 473 },
          },
        }}
      >
        <iframe title="video" src="https://www.youtube.com/embed/PAgB1cEZGcQ" />
      </Stack>
        {/*<Box mt={4}>
        {LINKS.map((link, i) => {
          return (
            <Typography className="center accent" variant="body2" key={link.text}>
              <Link
                href={link.url}
                sx={{
                  lineHeight: '30px',
                  textDecoration: 'none',
                  color: COLOR_ACCENT,
                }}
                target="_blank"
                rel="noreferrer"
              >
                {link.text}
              </Link>
            </Typography>
          );
        })}
      </Box>*/}
    </>
  );
}
