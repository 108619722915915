export enum EvmChains {
  Polygon = 'Polygon',
}

export enum Chains {
  Polygon = 'Polygon',
}

export enum Themes {
  dark = 'dark',
  light = 'light',
}
