import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import { routes } from 'app/providers/router/routes';
import { useWeb3Auth } from 'app/providers/Web3AuthProvider';
import { userSelector } from 'entities/user';
import { ConnectButton } from 'features/connect-button';
import { logoImage, logoImageNew } from 'shared/assets';
import { useShallowSelector } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isHomePage = pathname === '/';
  const { id: userId } = useShallowSelector(userSelector.getUser);
  const { logout } = useWeb3Auth();

  const { t, i18n } = useTranslation();
  const handleLogoClick = () => {
    navigate(routes.home.path);
  };

  const changeLanguageRU = () => {
    i18n.changeLanguage("ru")
  };
  const changeLanguageEN = () => {
    i18n.changeLanguage("en")
  };
  const changeLanguageID = () => {
    i18n.changeLanguage("id")
  };

  return (
    <Stack
      paddingTop={{ xs: 2.5, md: 5 }}
      paddingBottom={1.5}
      px={2}
      width="100%"
      justifyContent={{ xs: 'start', md: 'center' }}
      alignItems="center"
      position="relative"
      direction="row"
    >
      <Button variant="text" onClick={handleLogoClick}>

          <Box component="img" src={logoImageNew} alt="web3wed logo" sx={{
              width: { xs: 100, sm: 123 },
              height: { xs: 97, sm: 119 }
          }} />
      </Button>
      <Box
        sx={{
          position: 'absolute',
          right: { xs: 125, sm: 240, md: 295, lg: 365 },
            top: {xs: 10, sm: 66},
          zIndex: '3',
        }}
      >
        <Button variant="outlined"
            onClick={changeLanguageRU}
            sx={{
              width: { xs: 15, md: 15 },
              fontSize: '12px',
              whiteSpace: 'nowrap',
                minWidth: '50px',
                height: '45px',
                padding: '12px 15px',
            }}
          >
            {t('header_lang_ru')}
          </Button>
      </Box>
      <Box
          sx={{
            position: 'absolute',
            right: { xs: 70, sm: 185, md: 240, lg: 310 },
              top: {xs: 10, sm: 66},
            zIndex: '3',
          }}
      >
        <Button variant="outlined"
            onClick={changeLanguageEN}
            sx={{
              width: { xs: 15, md: 15 },
              fontSize: '12px',
                padding: '12px 15px',
              whiteSpace: 'nowrap',
                height: '45px',
                minWidth: '50px'
            }}
          >
            {t('header_lang_en')}
          </Button>
      </Box>
        <Box
            sx={{
                position: 'absolute',
                right: { xs: 16, sm: 130, md: 185, lg: 255 },
                top: {xs: 10, sm: 66},
                zIndex: '3',
            }}
        >
            <Button variant="outlined"
                    onClick={changeLanguageID}
                    sx={{
                        width: { xs: 15, md: 15 },
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                        minWidth: '50px',
                        height: '45px',

                        padding: '12px 15px',
                    }}
            >
                {t('header_lang_id')}
            </Button>
        </Box>
      <Box
        sx={{
          position: 'absolute',
          right: { xs: 16, sm: 20, md: 30, lg: 100 },
            top: { xs: 60}
        }}
      >
        {userId && !isHomePage ? (
          <Button
            variant="outlined"
            onClick={logout}
            sx={{
              width: { xs: 105, md: 147 },
              fontSize: '16px',
              whiteSpace: 'nowrap',
            }}
          >
            {t('header_button_logout')}
          </Button>
        ) : (
          <ConnectButton />
        )}
      </Box>
    </Stack>
  );
};
