import { SyntheticEvent, useEffect, useState } from 'react';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useMediaQuery, useTheme } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import copy from 'copy-to-clipboard';
import { COLOR_STROKE } from 'shared/config/theme/colors';
import { CopyIcon } from 'shared/ui/components';
import { shortenPhrase } from 'shared/utils';

import { CopyButtonField } from './CopyButtonField';

type CopyAddressProps = {
  text: string;
};

export const CopyKey = ({ text }: CopyAddressProps) => {
  const theme = useTheme();
  const isDownSmLayout = useMediaQuery(theme.breakpoints.down('sm'));
  const hiddenText = isDownSmLayout ? '******************************' : '*********************';
  const shortenText = isDownSmLayout ? shortenPhrase(text, 10, 14) : shortenPhrase(text, 8, 9);
  const [helperText, setHelperText] = useState(hiddenText);
  const [isHidden, setIsHidden] = useState(true);

  const handleCopyText = () => {
    copy(text);
    setHelperText('Copied!');
  };

  const handleHideText = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (isHidden) {
      setHelperText(shortenText);
      setIsHidden(false);
    } else {
      setHelperText(hiddenText);
      setIsHidden(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setHelperText(isHidden ? hiddenText : shortenText);
    }, 1500);
    return () => clearTimeout(timer);
    // Reason: no need to setHelperText on isHidden change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, helperText]);

  useEffect(() => {
    if (isHidden) {
      setHelperText(hiddenText);
    } else {
      setHelperText(shortenText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownSmLayout]);

  return (
    <CopyButtonField onClick={handleCopyText} disabled={!text} id="key-field-btn">
      {helperText}
      {isHidden ? (
        <VisibilityOutlinedIcon
          sx={{ width: 24, height: 24, path: { fill: COLOR_STROKE }, position: 'absolute', right: '34px' }}
          onClick={handleHideText}
        />
      ) : (
        <VisibilityOffOutlinedIcon
          sx={{ width: 24, height: 24, path: { fill: COLOR_STROKE }, position: 'absolute', right: '34px' }}
          onClick={handleHideText}
        />
      )}
      <CopyIcon sx={{ width: 24, height: 24, path: { fill: COLOR_STROKE }, position: 'absolute', right: '8px' }} />
    </CopyButtonField>
  );
};
