import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { COLOR_BLACK } from '../colors';
import { BORDER_RADIUS_S } from '../common';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiPopoverOverrides = (theme: Theme): ComponentsOverrides['MuiPopover'] => ({
  root: {
    paper: {
      background: COLOR_BLACK,
    },
    '.MuiPaper-root': {
      background: COLOR_BLACK,
      borderRadius: BORDER_RADIUS_S,
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
    },
  },
});

export const getMuiPopoverDefaultProps = (): ComponentsProps['MuiPopover'] => ({
  elevation: 1,
  disablePortal: true,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
});
