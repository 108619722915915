import { SvgIcon, SvgIconProps } from '@mui/material';

export const Facebook = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon width="38" height="38" viewBox="0 0 38 38" fill="none" sx={sx} {...props}>
    <g clipPath="url(#clip0_2355_3128)">
      <path
        d="M37.9173 18.9997C37.9173 8.69035 29.56 0.333008 19.2507 0.333008C8.94133 0.333008 0.583984 8.69035 0.583984 18.9997C0.583984 28.3166 7.41008 36.0392 16.334 37.4396V24.3955H11.5944V18.9997H16.334V14.8872C16.334 10.2088 19.1209 7.62468 23.3847 7.62468C25.4263 7.62468 27.5632 7.98926 27.5632 7.98926V12.583H25.2094C22.8907 12.583 22.1673 14.022 22.1673 15.4997V18.9997H27.3444L26.5168 24.3955H22.1673V37.4396C31.0912 36.0392 37.9173 28.3166 37.9173 18.9997Z"
        fill="#1877F2"
      />
      <path
        d="M26.5161 24.3958L27.3438 19H22.1667V15.5C22.1667 14.0238 22.89 12.5833 25.2088 12.5833H27.5625V7.98958C27.5625 7.98958 25.4264 7.625 23.384 7.625C19.1202 7.625 16.3333 10.2092 16.3333 14.8875V19H11.5938V24.3958H16.3333V37.4399C18.266 37.7423 20.234 37.7423 22.1667 37.4399V24.3958H26.5161Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2355_3128">
        <rect width="37.3333" height="37.3333" fill="white" transform="translate(0.583984 0.333008)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
