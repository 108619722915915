import { toast } from 'react-toastify';
import { weddingActions } from 'entities/wedding';
import { witnessActions } from 'entities/witness/model';
import { connectSocket, error, request, socket, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { camelize, logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { witnessApi } from '../ajax';

export function* connectWitnessSaga({
  type,
  payload: { engagementId, invitationCode, engagementType },
}: SagaPayload<typeof witnessActions.connectWitness>) {
  yield* put(request(type));
  try {
    if (socket) {
      socket.close();
    }
    const data = yield* call(witnessApi.getWitnessWsToken, { engagementId, invitationCode });
    const { accessToken } = camelize(data);
    connectSocket(accessToken, engagementType);

    yield put(weddingActions.updateWedding({ id: engagementId }));

    yield* put(success(type));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    yield* put(error(type, err));
    toast.error(err?.data?.detail);
    logger('connectWitness', err);
  }
}

export default function* listener() {
  yield takeLatest(witnessActions.connectWitness.type, connectWitnessSaga);
}
