import { coupleReducer } from 'entities/couple';
import { modalReducer } from 'entities/modal';
import { modelReducer } from 'entities/models';
import { ringReducer } from 'entities/ring';
import { userReducer } from 'entities/user';
import { weddingReducer } from 'entities/wedding';
import { witnessReducer } from 'entities/witness';
import { uiReducer } from 'shared/config/ui/reducer';

export default {
  ui: uiReducer,
  modal: modalReducer,
  user: userReducer,
  couple: coupleReducer,
  model: modelReducer,
  wedding: weddingReducer,
  ring: ringReducer,
  witness: witnessReducer,
};
