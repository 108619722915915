import { ABLogo, BenzingaLogo, CBSLogo, ChronicalLogo, Fox5Logo, KTLALogo, NBCLogo, YahooLogo } from 'shared/assets';

export const MEDIA_LOGOS = [
  {
    logo: YahooLogo,
    name: 'Yahoo',
    href: 'https://finance.yahoo.com/news/revolutionizing-marriage-web3wed-io-launches-202500022.html',
  },
  {
    logo: BenzingaLogo,
    name: 'Benzinga',
    href: 'https://www.benzinga.com/amp/content/32257053',
  },
  {
    logo: Fox5Logo,
    name: 'Fox 5',
    href: 'https://fox5sandiego.com/business/press-releases/ein-presswire/616693463/nft-based-blockchain-marriage-service-on-valentines-day-launched-by-w3wed-io/',
  },
  {
    logo: KTLALogo,
    name: 'KTLA',
    href: 'https://ktla.com/business/press-releases/ein-presswire/616693463/nft-based-blockchain-marriage-service-on-valentines-day-launched-by-w3wed-io/',
  },
  {
    logo: ChronicalLogo,
    name: 'The Chronical Journal',
    href: 'http://markets.chroniclejournal.com/chroniclejournal/news/article/marketersmedia-2023-2-19-w3wedio-launches-revolutionary-blockchain-marriage-service-with-nft-based-rings',
  },
  {
    logo: ABLogo,
    name: 'AP',
    href: 'https://apnews.com/press-release/ein-presswire-newsmatics/privacy-ein-presswire-newsmatics-weddings-marriage-26f7ea28ec21716c985b32133f1fed09',
  },
  {
    logo: CBSLogo,
    name: 'CBS',
    href: 'https://www.cbs42.com/business/press-releases/ein-presswire/616693463/nft-based-blockchain-marriage-service-on-valentines-day-launched-by-w3wed-io/',
  },
  {
    logo: NBCLogo,
    name: 'NBC',
    href: 'https://www.nbc4i.com/business/press-releases/ein-presswire/616693463/nft-based-blockchain-marriage-service-on-valentines-day-launched-by-w3wed-io/',
  },
];
