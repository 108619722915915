import { Control, Controller } from 'react-hook-form';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ConnectionFormState } from 'features/connection/model';
import { SexType } from 'shared/types';
import { useTranslation } from 'react-i18next';

interface GenderSelectBlockProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<ConnectionFormState, any>;
  disabled?: boolean;
}

export const GenderSelectBlock = ({ control, disabled }: GenderSelectBlockProps) => {
   const { t, i18n } = useTranslation();
  return (
    <Controller
      name="sex"
      control={control}
      render={({ field }) => (
        <RadioGroup {...field} name="sex" sx={{ flexDirection: 'row', justifyContent: 'center', gap: 5, ml: 1 }}>
          <FormControlLabel
            value={SexType.man}
            disabled={disabled}
            control={<Radio />}
            label={t('Groom')}
            sx={{ '.MuiFormControlLabel-label': { paddingLeft: 1.5 } }}
          />
          <FormControlLabel
            value={SexType.woman}
            disabled={disabled}
            control={<Radio />}
            label={t('Bride')}
            sx={{ '.MuiFormControlLabel-label': { paddingLeft: 1.5 } }}
          />
        </RadioGroup>
      )}
    />
  );
};
