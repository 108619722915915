import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import { SplideSlide } from '@splidejs/react-splide';
import Splide from '@splidejs/splide';
import { modelActions, modelSelector } from 'entities/models';
import { ModelCard } from 'entities/models/ui';
import { useShallowSelector } from 'shared/hooks';
import { Slider } from 'shared/ui/components';

export const ModelsContainer = () => {
  const dispatch = useDispatch();
  const { userModels } = useShallowSelector(modelSelector.getModel);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const sliderModelsData = userModels.map(({ id, meta }) => ({
    id: id || '',
    nickname: meta?.nickname || '',
    imageUrl: meta?.imageUrl || '',
  }));
  const sliderModelsData2 = [
    {
      id: '00',
      nickname: `${userModels[0].meta?.nickname}0` || '',
      imageUrl: userModels[0].meta?.imageUrl || '',
    },
    {
      id: '01',
      nickname: `${userModels[0].meta?.nickname}1` || '',
      imageUrl: userModels[0].meta?.imageUrl || '',
    },
    {
      id: '02',
      nickname: `${userModels[0].meta?.nickname}2` || '',
      imageUrl: userModels[0].meta?.imageUrl || '',
    },
  ];

  const handleSlideMove = (splide: Splide, index: number) => {
    setActiveSlideIndex(index);
  };

  const handleSlelectModel = () => {
    const nftModelId = sliderModelsData[activeSlideIndex].id;
    dispatch(modelActions.selectNftModel({ nftModelId }));
  };

  return (
    <>
      <Box mt={{ xs: 3.5, md: 5 }} mb={{ xs: 10, md: 3 }}>
        {sliderModelsData.length > 1 ? (
          <Slider disableHover pagination={false} arrowsMobileTop={428} onMoved={handleSlideMove}>
            {sliderModelsData.map((modelData) => (
              <SplideSlide key={modelData.id}>
                <ModelCard {...modelData} />
              </SplideSlide>
            ))}
          </Slider>
        ) : (
          <ModelCard {...sliderModelsData[0]} />
        )}
      </Box>

      <Button variant="contained" onClick={handleSlelectModel} sx={{ width: 196, mt: 3 }}>
        Next
      </Button>
    </>
  );
};
