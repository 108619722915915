import { Grid, Link, Typography } from '@mui/material';
import { COLOR_ACCENT } from 'shared/config';
import { useTranslation } from 'react-i18next';

import { MEDIA_LOGOS } from './SeenOn.helpers';

export const SeenOn = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Typography className="center" variant="h2" mt={16} mb={6}>
          {t('main_SeenOn_p1')}
      </Typography>
      <Grid alignItems="center" justifyContent="center" rowGap={5.75} container>
        {MEDIA_LOGOS.map((media) => {
          return (
            <Grid
              item
              lg={3}
              md={4}
              xs={12}
              key={media.name}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Link href={media.href} sx={{}} target="_blank" rel="noreferrer">
                <img src={media.logo} alt={media.name} />
              </Link>
            </Grid>
          );
        })}
      </Grid>
      <Typography mt={7} className="center">
          {t('main_SeenOn_d1')}{' '}
        <Link
          sx={{
            textDecoration: 'none',
          }}
          color={COLOR_ACCENT}
          href="https://www.brandpush.co"
          target="_blank"
          rel="noreferrer"
        >
          BrandPush.co
        </Link>
      </Typography>
    </>
  );
};
