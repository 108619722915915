import { SvgIcon, SvgIconProps } from '@mui/material';

export const BlurEllipseBlue = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 1435 700" fill="none" sx={{ width: 1435, height: 700, overflow: 'visible', ...sx }} {...props}>
    <g opacity="0.3" filter="url(#filter0_f_450_4711)">
      <ellipse cx="590" cy="573.5" rx="256" ry="239.5" fill="#4DA3FF" />
    </g>
    <defs>
      <filter
        id="filter0_f_450_4711"
        x="0"
        y="0"
        width="1180"
        height="1147"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="167" result="effect1_foregroundBlur_450_4711" />
      </filter>
    </defs>
  </SvgIcon>
);
