import { toast } from 'react-toastify';
import { weddingActions } from 'entities/wedding';
import { error, request, socket, success } from 'shared/api';
import { Emits } from 'shared/types';
import { SagaPayload } from 'shared/types/globals';
import { logger, snakeize } from 'shared/utils';
import { put, takeLatest } from 'typed-redux-saga';

export function* confirmPreviewRingsSaga({ type }: SagaPayload<typeof weddingActions.confirmPreviewRings>) {
  yield* put(request(type));

  try {
    const data = { emit: Emits.ShowroomNext };
    socket?.send(JSON.stringify(snakeize(data)));
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    toast.error('Something went wrong');
    logger(weddingActions.confirmPreviewRings.type, err);
  }
}

export default function* listener() {
  yield takeLatest(weddingActions.confirmPreviewRings.type, confirmPreviewRingsSaga);
}
