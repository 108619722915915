import { SvgIcon, SvgIconProps } from '@mui/material';

export const Ellipse = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 715 716" fill="none" sx={{ width: 715, height: 716, ...sx }} {...props}>
    <path
      opacity={0.2}
      d="M494.925 494.943C403.968 585.9 306.266 652.856 221.848 687.591C179.638 704.959 140.773 714.262 107.746 714.498C74.7218 714.734 47.5758 705.906 28.7288 687.059C9.88173 668.212 1.05393 641.066 1.2898 608.042C1.5257 575.015 10.8285 536.15 28.1965 493.94C62.9319 409.521 129.888 311.819 220.844 220.863C311.801 129.906 409.503 62.9505 493.921 28.2151C536.131 10.847 574.996 1.54431 608.023 1.30841C641.047 1.07253 668.193 9.90032 687.04 28.7474C705.887 47.5944 714.715 74.7404 714.479 107.765C714.243 140.791 704.941 179.657 687.573 221.867C652.837 306.285 585.881 403.987 494.925 494.943Z"
      stroke="#D9D9D9"
      fill="transparent"
    />
  </SvgIcon>
);
