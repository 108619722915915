import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { persistor, store } from 'app/store';
import { PersistGate } from 'redux-persist/integration/react';
import { colorThemes, getTheme } from 'shared/config';
import { EvmChains } from 'shared/types';
import { ProgressCloseButton } from 'shared/ui/components';

import { Web3AuthProvider } from './Web3AuthProvider';

import 'react-toastify/dist/ReactToastify.css';

export { RouteManager } from './router';


interface GlobalProviderProps {
  children?: ReactNode;
}

export const GlobalProvider = ({ children }: GlobalProviderProps) => (
  <ThemeProvider theme={getTheme(colorThemes)}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Web3AuthProvider web3AuthNetwork="mainnet" chain={EvmChains.Polygon}>
          <BrowserRouter>
            <CssBaseline />
            <ToastContainer
              autoClose={4000}
              hideProgressBar
              position="bottom-right"
              closeButton={<ProgressCloseButton />}
            />
            {children}
          </BrowserRouter>
        </Web3AuthProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);
