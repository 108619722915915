import { api } from 'shared/api/axios';
import { CoupleResponse } from 'shared/types';

const getCoupleData = () => {
  return api({
    url: `/couple`,
    method: 'GET',
  });
};
const addPartner = (promoCode: string): Promise<CoupleResponse> => {
  return api({
    url: `/couple?promo_code=${promoCode}`,
    method: 'POST',
  });
};

export const coupleApi = {
  getCoupleData,
  addPartner,
};
