import { modalActions } from 'entities/modal';
import { userActions } from 'entities/user';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

const userActionTypes = Object.values(userActions).map((action) => action.type);

export const ignoredActions = [
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  modalActions.openModal.type,

  ...userActionTypes,
];
