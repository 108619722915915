import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PrivateIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 24"
    sx={{
      width: '25px',
      height: '24px',
      ...sx,
    }}
  >
    <path
      d="M12.5 12.5004C13.6046 12.5004 14.5 11.6049 14.5 10.5004C14.5 9.39579 13.6046 8.50036 12.5 8.50036C11.3954 8.50036 10.5 9.39579 10.5 10.5004C10.5 11.6049 11.3954 12.5004 12.5 12.5004ZM12.5 12.5004V15.5004M20.5 12.0004C20.5 16.4615 15.04 19.6941 13.1414 20.6833C12.9361 20.7903 12.8334 20.8438 12.691 20.8715C12.58 20.8931 12.42 20.8931 12.309 20.8715C12.1666 20.8438 12.0639 20.7903 11.8586 20.6833C9.95996 19.6941 4.5 16.4615 4.5 12.0004V8.21796C4.5 7.41845 4.5 7.01869 4.63076 6.67506C4.74627 6.3715 4.93398 6.10064 5.17766 5.88589C5.4535 5.6428 5.8278 5.50243 6.5764 5.22171L11.9382 3.21103C12.1461 3.13307 12.25 3.09409 12.357 3.07864C12.4518 3.06493 12.5482 3.06493 12.643 3.07864C12.75 3.09409 12.8539 3.13307 13.0618 3.21103L18.4236 5.22171C19.1722 5.50243 19.5465 5.6428 19.8223 5.88589C20.066 6.10064 20.2537 6.3715 20.3692 6.67506C20.5 7.01869 20.5 7.41845 20.5 8.21796V12.0004Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
