import { Button, styled } from '@mui/material';
import { BORDER_MAIN, BORDER_RADIUS_S, COLOR_ACCENT, COLOR_BG } from 'shared/config';
import { FontWeights } from 'shared/config/theme/Typography';

export const CopyButtonField = styled(Button)(({ theme }) => ({
  width: '233px',
  height: 36,
  background: COLOR_BG,
  border: BORDER_MAIN,
  borderRadius: BORDER_RADIUS_S,
  fontWeight: FontWeights.Regular,
  fontSize: 14,
  lineHeight: '20px',
  color: COLOR_ACCENT,
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  textTransform: 'unset',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    width: 296,
  },
}));
