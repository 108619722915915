import { toast } from 'react-toastify';
import { userActions } from 'entities/user/model';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { camelize, logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { userApi } from '../ajax';

export function* updateUserInfoSaga({ type, payload }: SagaPayload<typeof userActions.updateUserInfo>) {
  yield* put(request(type));
  try {
    const data = yield* call(userApi.updateUser, payload);
    yield* put(userActions.updateUserState(camelize(data)));
    yield* put(success(type));
    toast.success('You have successfully update your profile info');
  } catch (err) {
    yield* put(error(type, err));
    logger('updateUserInfo', err);
    toast.error('Something went wrong');
  }
}

export default function* listener() {
  yield takeLatest(userActions.updateUserInfo.type, updateUserInfoSaga);
}
