import { ApplePayImage, GooglePayImage, MasterImage, VisaImage } from 'shared/assets';
import { Tiktok } from 'shared/ui/components';

import { Discord, Telegram, Twitter, Youtube } from '../Icons';

export const SOCIALS = [
  {
    icon: <Twitter />,
    url: ' https://twitter.com/web3wedding',
  },
  {
    icon: <Discord />,
    url: ' https://discord.gg/yNCDvAr6Wp',
  },
  {
    icon: <Youtube />,
    url: ' https://www.youtube.com/@web3wedding',
  },
  {
    icon: <Tiktok />,
    url: 'https://www.tiktok.com/@web3wed',
  },
];

export const PAY_SERVICES = [
  {
    url: VisaImage,
    name: 'Visa',
  },

  {
    url: MasterImage,
    name: 'Mastercard',
  },

  {
    url: GooglePayImage,
    name: 'Google pay',
  },

  {
    url: ApplePayImage,
    name: 'Apple pay',
  },
];

export const LINKS = [
  {
    url: 'https://web3wed.gitbook.io/web3wed',
    text: 'Litepaper',
  },

  {
    url: 'https://linktr.ee/web3wed',
    text: 'LinkTree',
  },

  {
    url: 'https://web3wed.gitbook.io/web3wed/help',
    text: 'Help',
  },

  {
    url: 'https://web3wed.gitbook.io/web3wed/terms-of-use',
    text: 'Terms of use',
  },
];
