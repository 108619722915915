import { useDispatch } from 'react-redux';
import { CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { Close } from 'pages/home/ui/Icons';
import { COLOR_ACCENT, COLOR_LIGHT_GRAY_ALPHA_2, COLOR_LIGHT_GRAY_INPUT } from 'shared/config';
import { BORDER_RADIUS_XXL } from 'shared/config/theme/common';

import { modalActions, ModalType } from '../model';

export interface ILoadingPayload {
  type: ModalType.Loading;
  data: { title: string; text?: string };
}

export const LoadingModal = ({ data: { title, text } }: { data: ILoadingPayload['data'] }) => {
  const dispatch = useDispatch();
  return (
    <Stack
      spacing={5}
      sx={{
        paddingTop: { xs: 3, sm: 0 },
        paddingBottom: text ? 4 : 8,
        maxWidth: { xs: 328, sm: 654 },
        backgroundColor: 'rgb(25, 24, 54)',
        width: '100%',
        borderRadius: BORDER_RADIUS_XXL,
        position: 'relative',
      }}
    >
      <Close
        sx={{ color: COLOR_LIGHT_GRAY_INPUT, cursor: 'pointer', position: 'absolute', right: '16px', top: '16px' }}
        onClick={() => dispatch(modalActions.closeModal())}
      />
      <Typography variant="h3" className="center" sx={{ px: 2 }}>
        {title}
      </Typography>
      <Divider sx={{ background: COLOR_LIGHT_GRAY_ALPHA_2 }} />
      <Stack alignItems="center" spacing={3} px={3}>
        <CircularProgress size={123} sx={{ color: COLOR_ACCENT }} />
        {text && <Typography variant="subtitle2">{text}</Typography>}
      </Stack>
    </Stack>
  );
};
