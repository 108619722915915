import { GetEngagementDataReq, SendEngagementInitationReq } from 'entities/wedding';
import { api } from 'shared/api/axios';
import { FeedbackRequest, UpdateUserRequest, UserResponse } from 'shared/types';

const getUser = (): Promise<UserResponse> => {
  return api({
    url: '/user',
    method: 'GET',
  });
};
const updateUser = (data: UpdateUserRequest): Promise<UserResponse> => {
  return api({
    url: '/user',
    method: 'PATCH',
    data,
  });
};

const getWsToken = ({ engagementId }: GetEngagementDataReq) => {
  return api({
    url: `/engagement/login-ws?engagement_id=${engagementId}`,
    method: 'POST',
  });
};

const sendEngagementInvitation = ({ email }: SendEngagementInitationReq) => {
  return api({
    url: `/engagement/invitation/?receiver_email=${email}`,
    method: 'POST',
  });
};

const getCoupleData = () => {
  return api({
    url: `/couple`,
    method: 'GET',
  });
};

const sendFeedback = (data: FeedbackRequest) => {
  return api({
    url: '/feedback',
    method: 'POST',
    data,
  });
};

export const userApi = {
  sendFeedback,
  getUser,
  updateUser,
  getCoupleData,
  getWsToken,
  sendEngagementInvitation,
};
