import { CHAIN_NAMESPACES, CustomChainConfig } from '@web3auth/base';
import { EvmChains } from 'shared/types';

export const EVM_CHAIN_CONFIG = {
  [EvmChains.Polygon]: {
    mainnet: {
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      chainId: '0x89', // hex of 137, polygon mainnet
      rpcTarget: 'https://rpc.ankr.com/polygon',
      displayName: 'Polygon Mainnet',
      blockExplorer: 'https://polygonscan.com',
      ticker: 'MATIC',
      tickerName: 'Matic',
    } as CustomChainConfig,
    testnet: {
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      chainId: '0x13881', // hex of 80001, polygon testnet
      rpcTarget: 'https://rpc-mumbai.maticvigil.com/',
      displayName: 'Polygon Testnet',
      blockExplorer: 'https://polygon-mumbai-bor.publicnode.com/',
      ticker: 'MATIC',
      tickerName: 'Matic',
    } as CustomChainConfig,
  },
};

export type EVM_CHAIN_CONFIG_TYPE = keyof typeof EVM_CHAIN_CONFIG;
