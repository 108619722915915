import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'app/store';
import { nftModelInitialState, NftModelState } from 'entities/models';
import { EngagementType, Nullable, SexType } from 'shared/types';

import { AddPartnerReq } from './types';

export type UserCoupleState = {
  address: string;
  createdAt: Nullable<string>;
  email: string;
  firstName: string;
  id: string;
  isRegistered: boolean;
  lastName: string;
  profileImage: string;
  promoCode: string;
  sex: Nullable<SexType>;
};
export type CoupleState = {
  createdAt: Nullable<string>;
  id: string;
  engagementType: Nullable<EngagementType>;
  isWaitlisted: boolean;
  partner: UserCoupleState;
  user: UserCoupleState;
  nftModel: NftModelState;
};

export const userCoupleInitialState: UserCoupleState = {
  address: '',
  createdAt: null,
  email: '',
  firstName: '',
  id: '',
  isRegistered: false,
  lastName: '',
  profileImage: '',
  promoCode: '',
  sex: null,
};
export const initialState: CoupleState = {
  id: '',
  createdAt: null,
  engagementType: null,
  isWaitlisted: false,
  partner: userCoupleInitialState,
  user: userCoupleInitialState,
  nftModel: nftModelInitialState,
};

export const coupleSelector = {
  getCouple: (state: State): CoupleState => state.couple,
};

const coupleSlice = createSlice({
  name: 'couple',
  initialState,
  reducers: {
    updateCoupleState: (state, action: PayloadAction<Partial<CoupleState>>) => ({
      ...state,
      ...action.payload,
    }),

    clearCoupleState: () => ({
      ...initialState,
    }),

    /** For saga */
    getCouple(state, action: PayloadAction) {},
    addPartner(state, action: PayloadAction<AddPartnerReq>) {},
  },
});

export const { reducer: coupleReducer } = coupleSlice;
export const { actions: coupleActions } = coupleSlice;
