import { SvgIcon, SvgIconProps } from '@mui/material';

export const Tiktok = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" sx={{ width: 24, height: 24, ...sx }} {...props}>
    <path
      d="M17.0725 0H13.0278V16.3478C13.0278 18.2957 11.4722 19.8957 9.53626 19.8957C7.60034 19.8957 6.04469 18.2957 6.04469 16.3478C6.04469 14.4348 7.56577 12.8695 9.43257 12.8V8.69567C5.31872 8.7652 2 12.1391 2 16.3478C2 20.5913 5.38786 24 9.57085 24C13.7538 24 17.1416 20.5565 17.1416 16.3478V7.9652C18.6627 9.07827 20.5295 9.73913 22.5 9.77393V5.66957C19.4579 5.56522 17.0725 3.06087 17.0725 0Z"
      fill="currentColor"
    />
  </SvgIcon>
);
