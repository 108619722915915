import { Box, BoxProps, useTheme } from '@mui/material';
import { Heart1, Heart2, Heart3 } from 'pages/home/ui/Icons/Heart';

type BackgroundWrapperProps = {
  background?: string;
} & BoxProps;

export const AnimatedWrapper = ({ background, ...boxProps }: BackgroundWrapperProps) => {
  const theme = useTheme();

  return (
    <Box
      {...boxProps}
      sx={{
        position: 'fixed',
        width: '100%',
        height: { xs: '100%', sm: '100vh' },
        // background: background || theme.themeColors.background,
        zIndex: -1,
        ...boxProps.sx,
      }}
    >
      <Box className="stars1" sx={{ position: 'absolute' }} />
      <Box className="stars2" sx={{ position: 'absolute' }} />
      <Box className="stars3" sx={{ position: 'absolute' }} />
      {/*<Box className="hearts1" sx={{ position: 'absolute', left: '2%', top: '30%' }}>
        <Heart1 sx={{ width: 180, height: 230 }} />
      </Box>
      <Box
        className="hearts2"
        sx={{ position: 'absolute', right: '30%', top: '-2%', display: { xs: 'none', sm: 'none', lg: 'block' } }}
      >
        <Heart1 sx={{ width: 180, height: 230 }} />
      </Box>
      <Box
        className="hearts3"
        sx={{ position: 'absolute', left: '27%', top: '55%', display: { xs: 'none', sm: 'none', lg: 'block' } }}
      >
        <Heart2 sx={{ width: 210, height: 190 }} />
      </Box>
      <Box
        className="hearts4"
        sx={{ position: 'absolute', right: '0%', top: '30%', display: { xs: 'none', sm: 'none', lg: 'block' } }}
      >
        <Heart3 sx={{ width: 273, height: 247 }} />
      </Box>
      <Box
        className="hearts5"
        sx={{ position: 'absolute', left: '1%', top: '80%', display: { xs: 'none', sm: 'none', lg: 'block' } }}
      >
        <Heart3 sx={{ width: 273, height: 247 }} />
      </Box>
      <Box className="hearts6" sx={{ position: 'absolute', right: '11%', top: '72%' }}>
        <Heart2 sx={{ width: 210, height: 190 }} />
      </Box>*/}
    </Box>
  );
};
