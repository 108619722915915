import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { useWeb3Auth } from 'app/providers/Web3AuthProvider';
import { userSelector } from 'entities/user';
import { witnessActions } from 'entities/witness';
import { RegisterWitnessReq } from 'entities/witness/model/types';
import { COLOR_BG } from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

import {
  witnessConnectionDefaultValues,
  WitnessConnectionFormState,
  witnessConnectionValidationSchema,
} from './config';

export const WitnessConnectionForm = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading: isWeb3AuthLoading } = useWeb3Auth();
  const { firstName, lastName, email } = useShallowSelector(userSelector.getUser);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<WitnessConnectionFormState>({
    resolver: yupResolver(witnessConnectionValidationSchema),
    defaultValues: firstName && lastName ? { firstName, lastName } : witnessConnectionDefaultValues,
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const onSave: SubmitHandler<RegisterWitnessReq> = (data) => {
    dispatch(witnessActions.registerWitness(data));
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onSave)}
      gap={3}
      sx={{ alignItems: 'center', width: '100%', maxWidth: '550px', mx: 'auto', mt: { xs: 5, md: 9 } }}
    >
      <Stack spacing={4} width="100%">
        <TextField value={email} disabled placeholder={t('Email')} />
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder={t('Name')}
              disabled={!!firstName}
              value={firstName || field.value}
              error={!!errors.firstName}
              helperText={errors.firstName ? errors.firstName.message : ''}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder={t('FamilyName')}
              disabled={!!lastName}
              value={lastName || field.value}
              error={!!errors.lastName}
              helperText={errors.lastName ? errors.lastName.message : ''}
            />
          )}
        />
      </Stack>
      <Button
        type="submit"
        variant="contained"
        disabled={isSubmitting || !isValid || isWeb3AuthLoading}
        sx={{ mt: 5, width: 147 }}
      >
        {isSubmitting || isWeb3AuthLoading ? <CircularProgress size={30} sx={{ color: COLOR_BG }} /> : t('Next')}
      </Button>
    </Stack>
  );
};
