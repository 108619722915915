import { useEffect, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import copy from 'copy-to-clipboard';
import { COLOR_BG, COLOR_WHITE_ALPHA_3 } from 'shared/config';
import { FontWeights } from 'shared/config/theme/Typography';
import { CopyIcon } from 'shared/ui/components';

type CopyButtonProps = {
  text: string;
  variant: 'outlined' | 'code';
  size?: 'large' | 'small';
  disabled?: boolean;
};
export const CopyTextField = ({ text, variant, disabled = false, size = 'large' }: CopyButtonProps) => {
  const [helperText, setHelperText] = useState(text);
  const handleCopyText = () => {
    copy(text);
    setHelperText('Copied!');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setHelperText(text);
    }, 1500);
    return () => clearTimeout(timer);
  }, [text, helperText]);

  return variant === 'code' ? (
    <TextField
      value={helperText}
      disabled
      fullWidth
      sx={{
        width: 190,
        '.MuiInputBase-root': {
          px: 2,
          py: 1.25,
          borderRadius: 4,
          '.MuiInputBase-input': { fontSize: '32px', textAlign: 'center' },
        },
      }}
      InputProps={{
        endAdornment: (
          <IconButton
            disabled={disabled}
            onClick={handleCopyText}
            sx={{
              border: 'none',
              backgroundColor: COLOR_BG,
              width: 40,
              height: 40,
              '&:hover': {
                background: COLOR_BG,
              },
            }}
          >
            <CopyIcon />
          </IconButton>
        ),
      }}
    />
  ) : (
    <TextField
      value={helperText}
      disabled
      fullWidth
      sx={{
        '.MuiInputBase-root': {
          px: size === 'large' ? 2 : 1,
          py: size === 'large' ? 1.25 : 1,
          height: size === 'large' ? '48px' : '38px',
          background: COLOR_WHITE_ALPHA_3,
          '&.Mui-disabled': { background: COLOR_WHITE_ALPHA_3 },
          '.MuiInputBase-input': { fontWeight: FontWeights.Regular },
        },
      }}
      InputProps={{
        endAdornment: (
          <IconButton
            disabled={disabled}
            onClick={handleCopyText}
            sx={{
              border: 'none',
              width: 40,
              height: 40,
              '&:active, &:hover': {
                background: 'transparent',
              },
            }}
          >
            <CopyIcon />
          </IconButton>
        ),
      }}
    />
  );
};
