import { Nullable } from 'shared/types';
import { SexType } from 'shared/types/api/SexType';
import * as Yup from 'yup';

export interface ConnectionFormState {
  email?: string;
  firstName?: string;
  lastName: string;
  sex: Nullable<SexType>;
  agree: boolean;
}
const englishRegex = /^[a-zA-Z\s]*$/;
export const connectionValidationSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      'Enter correct email!',
    )
    .required('Email is required'),
  firstName: Yup.string()
    .min(3, 'Name is too short')
    .max(30, 'Maximum is 30 symbols')
    .matches(englishRegex, 'Only english is allowed')
    .required('Name is required'),
  lastName: Yup.string()
    .min(3, 'Name is too short')
    .max(30, 'Maximum is 30 symbols')
    .matches(englishRegex, 'Only english is allowed')
    .required('Name is required'),
  sex: Yup.string().required('Required'),
  agree: Yup.boolean().oneOf([true], 'Required'),
});

export const connectionDefaultValues: Partial<ConnectionFormState> = {
  email: '',
  firstName: '',
  lastName: '',
  sex: null,
  agree: false,
};
