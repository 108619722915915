import { SvgIcon, SvgIconProps } from '@mui/material';

export const BlurEllipseBlack = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 1435 700" fill="none" sx={{ width: 1435, height: 700, overflow: 'visible', ...sx }} {...props}>
    <g opacity="0.5" filter="url(#filter0_f_450_4710)">
      <ellipse cx="639.5" cy="609" rx="360.5" ry="330" fill="#02010B" />
    </g>
    <defs>
      <filter
        id="filter0_f_450_4710"
        x="0"
        y="0"
        width="1279"
        height="1218"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="139.5" result="effect1_foregroundBlur_450_4710" />
      </filter>
    </defs>
  </SvgIcon>
);
