import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Stack, styled, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import BigNumber from 'bignumber.js';
import { weddingActions, weddingSelector } from 'entities/wedding';
import { COLOR_ACCENT, COLOR_LIGHT_RED, uiSelector } from 'shared/config';
import { BORDER_DASHED, BORDER_MAIN_BOLD, BORDER_RADIUS_S } from 'shared/config/theme/common';
import { useShallowSelector, useValidateInputField, ValidationTypes } from 'shared/hooks';
import { CurvedArrowIcon } from 'shared/ui/components';
import { checkRequestStatusEquality } from 'shared/utils';
import { useTranslation } from 'react-i18next';

const BorderWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2, 2, 2.5),
  width: 408,
  border: BORDER_MAIN_BOLD,
  borderRadius: BORDER_RADIUS_S,
  [theme.breakpoints.down('sm')]: {
    width: 330,
  },
}));

const StyledRow = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: BORDER_DASHED,
  padding: theme.spacing(2, 0, 3),
  '&.multiline': {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  '&.no-border': {
    borderBottom: 'none',
    padding: 0,
  },
}));

const PromocodeInput = styled(TextField)(({ theme }) => ({
  width: 118,
  '.MuiInputBase-root': {
    height: '48px',
    width: 118,
    padding: theme.spacing(1.75),
    '.MuiInputBase-input': { fontSize: '16px' },
  },
  '&.success': {
    '.MuiInputBase-root': { borderColor: COLOR_ACCENT },
  },
  '&.error': {
    '.MuiInputBase-root': { borderColor: COLOR_LIGHT_RED },
  },
}));

type BillProps = {
  firstRingPrice: number;
  firstDiamondPrice: number;
  firstDiamondPriceInToken: number;
  secondRingPrice: number;
  secondDiamondPrice: number;
  secondDiamondPriceInToken: number;
  certificatePrice: number;
  fee: number;
  totalPrice: number;
  setPromocodeState: (v: string) => void;
};

export const Bill = ({
  firstRingPrice,
  firstDiamondPrice,
  firstDiamondPriceInToken,
  secondRingPrice,
  secondDiamondPrice,
  secondDiamondPriceInToken,
  certificatePrice,
  fee,
  totalPrice,
  setPromocodeState,
}: BillProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDownMdLayout = useMediaQuery(theme.breakpoints.down('md'));
  const uiState = useShallowSelector(uiSelector.getUI);
  const { isReferralExist, referralAddress, referralCodeOwnerAddress } = useShallowSelector(weddingSelector.getWedding);

  const [promocodeInputState, setPromocodeInputState] = useState('');
  const [promocode, handleChangePromocode, setPromocode] = useValidateInputField({
    type: ValidationTypes.string,
  });

  const isGetReferralLoading = checkRequestStatusEquality(uiState[weddingActions.getReferral.type]);

  useEffect(() => {
    if (promocode.length === 6) {
      setPromocodeInputState('');
      dispatch(weddingActions.getReferral({ referralCode: promocode }));
    } else {
      setPromocodeInputState('');
    }
  }, [dispatch, promocode]);

  useEffect(() => {
    if (isGetReferralLoading) {
      setPromocodeInputState('');
    } else if (isReferralExist) {
      setPromocodeInputState('success');
      dispatch(
        weddingActions.getPaymentLink({
          referralCodeOwnerAddress: referralAddress,
        }),
      );
    } else if (isReferralExist === false) {
      setPromocodeInputState('error');
      dispatch(
        weddingActions.getPaymentLink({
          referralCodeOwnerAddress: null,
        }),
      );
    } else {
      setPromocodeInputState('');
      dispatch(
        weddingActions.getPaymentLink({
          referralCodeOwnerAddress: null,
        }),
      );
    }
  }, [isReferralExist, isGetReferralLoading, dispatch, referralAddress]);

  useEffect(() => {
    if (referralCodeOwnerAddress) setPromocodeInputState('success');
  }, [referralCodeOwnerAddress]);

  useEffect(() => {
    setPromocodeState(promocodeInputState);
  }, [promocodeInputState, setPromocodeState]);

  return (
    <BorderWrapper>
      <StyledRow className="multiline">
        <StyledRow className="no-border">
          <Typography variant="body2">{t('bill_first_ring')}</Typography>
          <Typography>${new BigNumber(firstRingPrice).toFixed(2, 0).toString()}</Typography>
        </StyledRow>
        {!!firstDiamondPrice && (
          <StyledRow className="no-border">
            <Typography variant="body2">
              <CurvedArrowIcon /> w/diamond {isDownMdLayout && <br />}
              (WBTC {new BigNumber(firstDiamondPriceInToken).toFixed(2, 0).toString()})
            </Typography>
            <Typography variant="body2">${new BigNumber(firstDiamondPrice).toFixed(2, 0).toString()}</Typography>
          </StyledRow>
        )}
      </StyledRow>
      <StyledRow className="multiline">
        <StyledRow className="no-border">
          <Typography variant="body2">{t('bill_second_ring')}</Typography>
          <Typography>${new BigNumber(secondRingPrice).toFixed(2, 0).toString()}</Typography>
        </StyledRow>
        {!!secondDiamondPrice && (
          <StyledRow className="no-border">
            <Typography variant="body2">
              <CurvedArrowIcon /> w/diamond {isDownMdLayout && <br />}
              (WBTC {new BigNumber(secondDiamondPriceInToken).toFixed(2, 0).toString()})
            </Typography>
            <Typography variant="body2">${new BigNumber(secondDiamondPrice).toFixed(2, 0).toString()}</Typography>
          </StyledRow>
        )}
      </StyledRow>
      <StyledRow>
        <Typography variant="body2">{t('bill_certificate')}</Typography>
        <Typography variant="body2" ml="auto">
          ${new BigNumber(certificatePrice).toFixed(2, 0).toString()}
        </Typography>
        {promocodeInputState === 'success' && (
          <Typography variant="body2" className="dark-gray line-through" ml={1.5}>
            ${new BigNumber(certificatePrice + 12).toFixed(2, 0).toString()}
          </Typography>
        )}
      </StyledRow>
      <StyledRow>
        <Typography className="semi-bold">{t('bill_fee')}</Typography>
        <Typography className="semi-bold">${new BigNumber(fee).toFixed(2, 0).toString()}</Typography>
      </StyledRow>

      <Stack spacing={1.25} mt={2}>
        <Typography className="md medium gray">{t('bill_promo')}</Typography>

        <Stack direction="row" spacing={1.75} alignItems="center">
          <PromocodeInput
            value={promocode}
            onChange={handleChangePromocode}
            className={promocodeInputState}
            InputProps={{ inputProps: { maxLength: 6 } }}
            disabled={!!referralCodeOwnerAddress}
            placeholder={referralCodeOwnerAddress ? 'Activated' : ''}
          />
          {promocodeInputState === 'success' && <CheckIcon sx={{ width: '20px', color: COLOR_ACCENT }} />}
          {promocodeInputState === 'error' && <CloseIcon sx={{ width: '20px', color: COLOR_LIGHT_RED }} />}
          {isGetReferralLoading && <CircularProgress size="1rem" sx={{ color: COLOR_ACCENT }} />}
        </Stack>
      </Stack>

      <Stack mt={2} alignItems="end">
        <Typography className="gray semi-bold" mb={2}>
          {t('bill_total')}:
        </Typography>
        <Typography variant="h2" fontSize="36px" className="regular primary white">
          ${new BigNumber(totalPrice).toFixed(2, 0).toString()}
        </Typography>
        <Typography
          variant="body2"
          className="dark-gray line-through"
          visibility={promocodeInputState === 'success' ? 'unset' : 'hidden'}
        >
          ${new BigNumber(totalPrice + 12).toFixed(2, 0).toString()}
        </Typography>
      </Stack>
    </BorderWrapper>
  );
};
