import * as Yup from 'yup';

export interface WitnessConnectionFormState {
  firstName: string;
  lastName: string;
}
const englishRegex = /^[a-zA-Z\s]*$/;
export const witnessConnectionValidationSchema = Yup.object({
  firstName: Yup.string()
    .min(3, 'Name is too short')
    .max(30, 'Maximum is 30 symbols')
    .matches(englishRegex, 'Only english is allowed')
    .required('Name is required'),
  lastName: Yup.string()
    .min(3, 'Name is too short')
    .max(30, 'Maximum is 30 symbols')
    .matches(englishRegex, 'Only english is allowed')
    .required('Name is required'),
});

export const witnessConnectionDefaultValues: Partial<WitnessConnectionFormState> = {
  firstName: '',
  lastName: '',
};
