import { toast } from 'react-toastify';
import { coupleActions } from 'entities/couple/model';
import { userSelector } from 'entities/user/model';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { camelize, logger } from 'shared/utils';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { coupleApi } from '../ajax';

export function* getCoupleSaga({ type }: SagaPayload<typeof coupleActions.getCouple>) {
  yield* put(request(type));
  try {
    const { isRegistered, id: currentUserId } = yield select(userSelector.getUser);
    if (isRegistered) {
      const data = yield* call(coupleApi.getCoupleData);
      if (data) {
        const { user, partner, ...coupleData } = camelize(data);

        let partnerData;
        let userData;

        if (user.id?.toLowerCase() === currentUserId?.toLowerCase()) {
          partnerData = partner;
          userData = user;
        } else {
          partnerData = user;
          userData = partner;
        }

        yield* put(coupleActions.updateCoupleState({ partner: partnerData, user: userData, ...coupleData }));
      } else {
        yield* put(coupleActions.clearCoupleState());
      }
    }
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getCouple', err);
    toast.error('Something went wrong');
  }
}

export default function* listener() {
  yield takeLatest(coupleActions.getCouple.type, getCoupleSaga);
}
