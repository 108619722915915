import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, IconButton, Link, Stack, styled, Typography } from '@mui/material';
import { modelActions } from 'entities/models';
import { userSelector } from 'entities/user';
import { CopyAddress, CopyKey } from 'features/copy-address';
import {
  BORDER_ACCEN,
  BORDER_RADIUS_M,
  BORDER_RADIUS_XL,
  COLOR_WHITE_ALPHA_14,
  COLOR_WHITE_ALPHA_21,
} from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { InfoIcon, PrivateIcon, WalletIcon } from 'shared/ui/components';

const IconWrapper = styled(Stack)({
  width: 46,
  height: 46,
  border: BORDER_ACCEN,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
}) as typeof IconButton;

const BorderWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1.75),
  width: '100%',
  height: 182,
  border: `1px solid ${COLOR_WHITE_ALPHA_21}`,
  borderRadius: BORDER_RADIUS_XL,
  justifyContent: 'end',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const InfoBlock = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2.5, 4, 2.5, 2),
  width: '100%',
  borderRadius: BORDER_RADIUS_M,
  gap: theme.spacing(2),
  background: COLOR_WHITE_ALPHA_14,
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2.25),
    padding: theme.spacing(2),
    width: 328,
  },
}));

export const WalletKeyContainer = () => {
  const dispatch = useDispatch();
  const { address, privateKey } = useShallowSelector(userSelector.getUser);

  useEffect(() => {
    dispatch(modelActions.getUserNftModels());
    const interval = setInterval(() => dispatch(modelActions.getUserNftModels()), 10000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  return (
    <>
      <Typography variant="body2" className="center" mt={2} maxWidth={{ xs: 328, sm: 472 }} fontSize={15}>
        These are your wallet address and private key that were created by Web3Auth. Import it to Metamask to use on
        Opensea for buying Model`s NFT
      </Typography>

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2.5} my={2}>
        <BorderWrapper>
          <IconWrapper>
            <WalletIcon />
          </IconWrapper>
          <Typography variant="body2" className="center light gray" fontSize={15}>
            Wallet address
          </Typography>
          <CopyAddress text={address} />
        </BorderWrapper>

        <BorderWrapper>
          <IconWrapper>
            <PrivateIcon />
          </IconWrapper>
          <Typography variant="body2" className="center light gray" fontSize={15}>
            Private key:
          </Typography>
          <CopyKey text={privateKey} />
        </BorderWrapper>
      </Stack>

      <InfoBlock>
        <InfoIcon />
        <Typography variant="body2" fontSize={15}>
          If you already have a Model`s NFT on different wallet you must transfer NFT to this wallet to be able to marry
          with it.
        </Typography>
      </InfoBlock>

      <Button
        variant="contained"
        component={Link}
        href={process.env.REACT_APP_OPENSEA_COLLECTION_LINK}
        target="_blank"
        rel="noreferrer"
        sx={{ width: 196, mt: { xs: 15.5, md: 3 } }}
      >
        Got it
      </Button>
    </>
  );
};
