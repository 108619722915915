import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'app/store';

export type RingState = {
  collections: {
    id: string;
    imageUrl: string;
    name: string;
    contractAddress: string;
    scanUrl: string;
    priceUsd: number;
  }[];
  usdRate: number;
};

export const initialState: RingState = {
  collections: [],
  usdRate: 0,
};

export const ringSelector = {
  getRing: (state: State): RingState => state.ring,
  getUsdRate: (state: State): RingState['usdRate'] => state.ring.usdRate,
};

const ringSlice = createSlice({
  name: 'ring',
  initialState,
  reducers: {
    updateRingState: (state, action: PayloadAction<Partial<RingState>>) => ({
      ...state,
      ...action.payload,
    }),

    clearRingState: () => ({
      ...initialState,
    }),

    /** For saga */
    getCollections(state, action: PayloadAction) {},
    getUsdRate(state, action: PayloadAction) {},
  },
});

export const { reducer: ringReducer } = ringSlice;
export const { actions: ringActions } = ringSlice;
