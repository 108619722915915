import { ChangeEvent, ChangeEventHandler, useState } from 'react';

import { InputProps, validateOnlyNumbers, ValidationTypes } from './index';

export const useValidateInputField = (
  options: InputProps,
): [string, ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>, (value: string) => void] => {
  const { type, decimals, minValue, maxValue, initialState = '' } = options ?? {};
  const [inputValue, setInputValue] = useState(initialState);

  const handleChangeValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;

    if (value === '' || type === ValidationTypes.string) {
      setInputValue(value);
      return;
    }

    if (type === ValidationTypes.point) {
      if (value.match(/^[+x1-9]\d*$/)) {
        if (value[0] !== '+') setInputValue(`+${value}`);
        else setInputValue(value);
      }
      return;
    }

    const minNumberValue = minValue ? Number(minValue) : -Infinity;
    const maxNumberValue = maxValue ? Number(maxValue) : Infinity;

    if (type === ValidationTypes.number) {
      const isNumber = validateOnlyNumbers({ value, decimals });
      const isConditionsTrue = [isNumber, Number(value) >= minNumberValue, Number(value) <= maxNumberValue].every(
        (condition) => condition,
      );
      if (isConditionsTrue) {
        setInputValue(value);
      }
      return;
    }

    if (type === ValidationTypes.int) {
      const isInteger = validateOnlyNumbers({ value, isInteger: true, decimals });
      const isConditionsTrue = [isInteger, Number(value) >= minNumberValue, Number(value) <= maxNumberValue].every(
        (condition) => condition,
      );
      if (isConditionsTrue) {
        setInputValue(value);
      }
    }
  };

  return [inputValue, handleChangeValue, setInputValue];
};
