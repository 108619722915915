import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MessageRightIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 360 144"
    sx={{
      ...sx,
      width: '360px',
      height: '144px',
    }}
  >
    <mask id="path-1-inside-1_384_4099" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0.83252C7.16344 0.83252 0 7.99598 0 16.8325V127.167C0 136.004 7.16343 143.167 16 143.167H326.987C335.823 143.167 342.987 136.004 342.987 127.167V89.1188L360 72.1053L342.987 55.0919V16.8325C342.987 7.99596 335.823 0.83252 326.987 0.83252H16Z"
      />
    </mask>
    <path
      d="M342.987 89.1188L342.28 88.4117L341.987 88.7046V89.1188H342.987ZM360 72.1053L360.707 72.8124L361.415 72.1053L360.707 71.3982L360 72.1053ZM342.987 55.0919H341.987V55.5061L342.28 55.799L342.987 55.0919ZM1 16.8325C1 8.54826 7.71573 1.83252 16 1.83252V-0.16748C6.61116 -0.16748 -1 7.44369 -1 16.8325H1ZM1 127.167V16.8325H-1V127.167H1ZM16 142.167C7.71572 142.167 1 135.451 1 127.167H-1C-1 136.556 6.61114 144.167 16 144.167V142.167ZM326.987 142.167H16V144.167H326.987V142.167ZM341.987 127.167C341.987 135.451 335.271 142.167 326.987 142.167V144.167C336.376 144.167 343.987 136.556 343.987 127.167H341.987ZM341.987 89.1188V127.167H343.987V89.1188H341.987ZM359.293 71.3982L342.28 88.4117L343.694 89.8259L360.707 72.8124L359.293 71.3982ZM342.28 55.799L359.293 72.8124L360.707 71.3982L343.694 54.3848L342.28 55.799ZM341.987 16.8325V55.0919H343.987V16.8325H341.987ZM326.987 1.83252C335.271 1.83252 341.987 8.54825 341.987 16.8325H343.987C343.987 7.44368 336.376 -0.16748 326.987 -0.16748V1.83252ZM16 1.83252H326.987V-0.16748H16V1.83252Z"
      fill="#D8D8D8"
      mask="url(#path-1-inside-1_384_4099)"
    />
  </SvgIcon>
);
