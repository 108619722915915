import { COLOR_BG, COLOR_GRAY_BORDER, COLOR_ACCENT, COLOR_ACCENT_LIGHT  } from '../colors.constants';

export const colorsButton = {
  colorButtonBorderDisabled: COLOR_GRAY_BORDER,

  // contained button theme
  colorButtonContainedBackground: COLOR_ACCENT,
  colorButtonContainedColor: COLOR_BG,
  colorButtonContainedColorHover: COLOR_BG,
  colorButtonContainedBorder: `1px solid ${COLOR_ACCENT}`,
  colorButtonContainedBorderHover: `1px solid ${COLOR_ACCENT_LIGHT}`,
  colorButtonContainedBackgroundHover: COLOR_ACCENT_LIGHT,

  // outlined button theme
  colorButtonOutlinedBackground: 'transparent',
  colorButtonOutlinedColor: COLOR_ACCENT,
  colorButtonOutlinedColorHover: COLOR_BG,
  colorButtonOutlinedBorder: `1px solid ${COLOR_ACCENT}`,
  colorButtonOutlinedBorderHover: `1px solid ${COLOR_ACCENT}`,
  colorButtonOutlinedBackgroundHover: COLOR_ACCENT,
};
