import { toast } from 'react-toastify';
import { userActions } from 'entities/user';
import { witnessActions } from 'entities/witness/model';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { camelize, logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { witnessApi } from '../ajax';

export function* registerWitnessSaga({
  type,
  payload: { firstName, lastName },
}: SagaPayload<typeof witnessActions.registerWitness>) {
  yield* put(request(type));
  try {
    const data = yield* call(witnessApi.registerWitnesses, { firstName, lastName });

    yield put(userActions.updateUserState(camelize(data)));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    toast.error('Something went wrong');
    logger('registerWitness', err);
  }
}

export default function* listener() {
  yield takeLatest(witnessActions.registerWitness.type, registerWitnessSaga);
}
