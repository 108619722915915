import { Ring1Image, Ring2Image, Ring3Image } from 'shared/assets';

export type CollectionRingsProps = {
  diamond: string;
  gold: string;
  stardust: string;
};

export const collectionRingsMock: CollectionRingsProps = {
  diamond: Ring1Image,
  gold: Ring2Image,
  stardust: Ring3Image,
};
