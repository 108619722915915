import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, SxProps } from '@mui/material';
import { routes, wsWeddingRoutes } from 'app/providers/router/routes';
import { modalActions, ModalType } from 'entities/modal';
import { userSelector } from 'entities/user';
import { weddingSelector } from 'entities/wedding/model';
import { useShallowSelector } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

type ConnectButtonProps = {
  size?: 'large' | 'small';
  sx?: SxProps;
};

export const ConnectButton = ({ size = 'small', sx }: ConnectButtonProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id: userId } = useShallowSelector(userSelector.getUser);
  const { state } = useShallowSelector(weddingSelector.getWedding);

  const handleButtonClick = () => {
    if (!userId) {
      dispatch(
        modalActions.openModal({
          type: ModalType.ConnectWallet,
        }),
      );
    } else {
      navigate(state ? wsWeddingRoutes[state] : routes.connection.path);
    }
  };

  return (
    <Button
      variant="outlined"
      onClick={handleButtonClick}
      sx={{ width: { xs: 105, md: size === 'large' ? 156 : 147 }, fontSize: '16px', whiteSpace: 'nowrap', ...sx }}
    >
      {userId ? t('header_button_profile'): t('header_button_signin')}
    </Button>
  );
};
