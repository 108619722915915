import { api } from 'shared/api/axios';

import { GetReferralReq } from '../types';

const getEngagementData = () => {
  return api({
    url: `/engagement`,
    method: 'GET',
  });
};
const getReferral = ({ referralCode }: GetReferralReq) => {
  return api({
    url: `/engagement/referral/owner?referral_code=${referralCode}`,
    method: 'GET',
  });
};

export const weddingApi = {
  getEngagementData,
  getReferral,
};
