import { weddingApi } from 'entities/wedding/api/ajax';
import { weddingActions } from 'entities/wedding/model';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

export function* getEngagementIdSaga({ type }: SagaPayload<typeof weddingActions.getEngagementId>) {
  yield* put(request(type));
  try {
    const engagementsArr = yield* call(weddingApi.getEngagementData);
    const engagementId = engagementsArr[0].id;
    yield put(weddingActions.updateWedding({ id: engagementId }));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('getEngagementId', err);
  }
}

export default function* listener() {
  yield takeLatest(weddingActions.getEngagementId.type, getEngagementIdSaga);
}
