import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    sx={{
      ...sx,
      width: '24px',
      height: '24px',
    }}
  >
    <g filter="url(#filter0_d_2508_14418)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3851 5.99657C17.9392 5.3808 18.8877 5.33088 19.5034 5.88507C20.1192 6.43926 20.1691 7.38769 19.6149 8.00346L10.6149 18.0035C10.04 18.6422 9.04703 18.6684 8.43934 18.0607L4.43934 14.0607C3.85355 13.4749 3.85355 12.5251 4.43934 11.9394C5.02513 11.3536 5.97487 11.3536 6.56066 11.9394L9.44271 14.8214L17.3851 5.99657Z"
        fill="#2DF6DE"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2508_14418"
        x="4"
        y="5.5"
        width="16"
        height="13.75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2508_14418" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2508_14418" result="shape" />
      </filter>
    </defs>
  </SvgIcon>
);
