import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { userActions, userSelector } from 'entities/user';
import { COLOR_ACCENT, COLOR_WHITE, COLOR_WHITE_ALPHA_21, uiSelector } from 'shared/config';
import { FontWeights } from 'shared/config/theme/Typography';
import { useShallowSelector, useTimeLeft, useValidateInputField, ValidationTypes } from 'shared/hooks';
import { checkRequestStatusEquality, getFormatedCounterDate } from 'shared/utils';

import { useTranslation } from 'react-i18next';

export const BorderWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 2),
  gap: theme.spacing(2.5),
  width: '100%',
  border: `1px solid ${COLOR_WHITE_ALPHA_21}`,
  borderRadius: 8,
}));

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  margin: theme.spacing(0, 1),
}));

export const ProposalBlock = () => {
  const { t, i18n } = useTranslation();
  const EMAIL_REGEX =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const dispatch = useDispatch();
  const uiState = useShallowSelector(uiSelector.getUI);
  const { proposalTimeout } = useShallowSelector(userSelector.getUser);
  const [checked, setChecked] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [fianceEmail, handleChangeFianceEmail, setFianceEmail] = useValidateInputField({
    type: ValidationTypes.string,
  });
  const timeLeft = useTimeLeft(proposalTimeout);
  const isProposalDisabled = !!timeLeft && +timeLeft.seconds !== 0;

  const isSendInvitationLoading = checkRequestStatusEquality(uiState[userActions.sendEngagementInvitation?.type]);

  const handleSendProposal = () => {
    dispatch(userActions.sendEngagementInvitation({ email: fianceEmail }));
  };

  useEffect(() => {
    if (EMAIL_REGEX.test(fianceEmail)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fianceEmail]);
  return (
    <BorderWrapper sx={{ borderColor: checked ? COLOR_ACCENT : COLOR_WHITE_ALPHA_21 }}>
      <FormControlLabel
        checked={checked}
        onChange={() => setChecked((prev) => !prev)}
        control={<StyledCheckBox />}
        label={<Typography>{t('connection_fiance_p1')}</Typography>}
      />
      {checked && (
        <>
          <Typography maxWidth={400}>{t('connection_fiance_p2')}</Typography>
          <Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2.5}>
              <TextField
                value={fianceEmail}
                fullWidth
                onChange={handleChangeFianceEmail}
                placeholder={t('connection_fiance_proposal_d1')}
                disabled={isProposalDisabled}
                sx={{
                  width: '274px',
                  '.MuiInputBase-root': {
                    px: 2,
                    py: 1.25,
                    '.MuiInputBase-input': { textAlign: 'center', fontWeight: FontWeights.Regular },
                  },
                }}
              />
              <Button
                disabled={!isEmailValid || isProposalDisabled || isSendInvitationLoading}
                variant="outlined"
                sx={{ whiteSpace: 'nowrap', width: '173px' }}
                size="medium"
                onClick={handleSendProposal}
              >
                {isSendInvitationLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : t('connection_fiance_proposal_send')}
              </Button>
            </Stack>
            {isProposalDisabled && (
              <Typography variant="body2" ml={0.5} mt={0.5} className="gray">
                {t('connection_fiance_proposal_resend')} 0:{getFormatedCounterDate(timeLeft?.seconds)}
              </Typography>
            )}
          </Stack>
        </>
      )}
    </BorderWrapper>
  );
};
