import { userActions } from 'entities/user/model';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { logger } from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { userApi } from '../ajax';

export function* sendFeedbackSaga({ type, payload }: SagaPayload<typeof userActions.sendFeedback>) {
  yield* put(request(type));
  try {
    yield* call(userApi.sendFeedback, { ...payload });
    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('sendFeedback', err);
  }
}

export default function* listener() {
  yield takeLatest(userActions.sendFeedback.type, sendFeedbackSaga);
}
