import { Navigate } from 'react-router-dom';
import { userSelector } from 'entities/user';
import { useShallowSelector } from 'shared/hooks';

type ProtectedRouteProps = {
  outlet: JSX.Element;
};

export const PrivateRoute = ({ outlet }: ProtectedRouteProps) => {
  const { id } = useShallowSelector(userSelector.getUser);
  if (!id) {
    return <Navigate to="/" replace />;
  }

  return outlet;
};
