import { useState } from 'react';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { IconButton, Link, Popover, Stack, styled, Typography } from '@mui/material';
import { userSelector } from 'entities/user';
import { weddingSelector } from 'entities/wedding';
import { saveAs } from 'file-saver';
import { COLOR_DARK, COLOR_WHITE } from 'shared/config';
import { BORDER_MAIN, BORDER_RADIUS_XL } from 'shared/config/theme/common';
import { useShallowSelector } from 'shared/hooks';
import { SexType } from 'shared/types';
import { CopyTextField } from 'shared/ui/components';
import { useTranslation } from 'react-i18next';

export const StyledIconButton = styled(IconButton)({
  color: COLOR_WHITE,
  width: 46,
  height: 46,
  '&:hover': {
    color: COLOR_DARK,
  },
}) as typeof IconButton;

export const BorderBlock = styled(Stack)(({ theme }) => ({
  borderRadius: BORDER_RADIUS_XL,
  border: BORDER_MAIN,
  width: 190,
  height: 160,
  padding: theme.spacing(2.5, 2.5, 1.5, 1.5),
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: 160,
    height: 150,
    padding: theme.spacing(1.75),
    p: {
      fontSize: '15px',
      lineHeight: '24px',
    },
  },
}));

const StyledWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1.5),
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    maxWidth: 344,
    flexWrap: 'wrap',
  },
}));

type CertificateActionButtonsProps = {
  type: 'ring' | 'certificate';
};

export const CertificateActionButtons = ({ type }: CertificateActionButtonsProps) => {
  const { t, i18n } = useTranslation();
  const { certificate, members } = useShallowSelector(weddingSelector.getWedding);
  const { address, privateKey } = useShallowSelector(userSelector.getUser);
  const { id: currentUserId, sex: currentUserSex } = useShallowSelector(userSelector.getUser);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openPopoverId, setOpenPopoverId] = useState<string | undefined>(undefined);

  const firstMemberId = members[0]?.user.id;
  const isCurrentUserFirst = firstMemberId === currentUserId;

  const ringImg: string = isCurrentUserFirst
    ? members[1]?.token?.meta?.imageUrl || ''
    : members[0]?.token?.meta?.imageUrl || '';

  const walletLink = type === 'certificate' ? certificate?.vaultContractUrl : undefined;
  const ringNftLink = currentUserSex === SexType.man ? certificate?.husbandNftRing : certificate?.wifeNftRing;
  const polygonscanLink = type === 'certificate' ? certificate?.collectionContractUrl : ringNftLink;

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setOpenPopoverId(id);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setOpenPopoverId(undefined);
  };

  const downloadCertificate = () => {
    if (type === 'certificate') {
      saveAs(certificate?.imageUrl || '', 'Marriage_Certificate');
    }
    if (type === 'ring') {
      saveAs(ringImg, 'Ring');
    }
  };

  return (
    <StyledWrapper>
      {type === 'certificate' ? (
        <BorderBlock>
          <Typography>{t('certificate_action_b_2')}</Typography>
          <StyledIconButton component={Link} href={walletLink} target="_blank" rel="noreferrer">
            <AccountBalanceWalletOutlinedIcon />
          </StyledIconButton>
        </BorderBlock>
      ) : (
        <BorderBlock>
          <Typography>{t('certificate_action_b_2')}</Typography>
          <StyledIconButton aria-describedby="keys" onClick={(e) => handleOpenPopover(e, 'keys')}>
            <AccountBalanceWalletOutlinedIcon />
          </StyledIconButton>
          <Popover
            id="keys"
            open={openPopoverId === 'keys'}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            PaperProps={{ sx: { width: 330 } }}
          >
            <Typography variant="body2">
              {t('certificate_action_b_3')}
            </Typography>
            <Typography variant="body2" mt={1} mb={0.5}>
              {t('certificate_action_b_4')}
            </Typography>
            <CopyTextField variant="outlined" text={address} size="small" />
            <Typography variant="body2" mt={1} mb={0.5}>
              {t('certificate_action_b_5')}
            </Typography>
            <CopyTextField variant="outlined" text={privateKey} size="small" />
          </Popover>
        </BorderBlock>
      )}
      <BorderBlock>
        <Typography>
          {t('certificate_action_b_6')}
          <br />
          {t('certificate_action_b_7')}
        </Typography>
        <StyledIconButton onClick={downloadCertificate}>
          <FileDownloadOutlinedIcon />
        </StyledIconButton>
      </BorderBlock>
      <BorderBlock>
        <Typography>
          {t('certificate_action_b_8')}
          <br />
          {t('certificate_action_b_9')}
        </Typography>
        <StyledIconButton component={Link} href={polygonscanLink} target="_blank" rel="noreferrer">
          <SearchOutlinedIcon />
        </StyledIconButton>
      </BorderBlock>
      <BorderBlock>
        <Typography>{t('certificate_action_b_10')}</Typography>
        <StyledIconButton aria-describedby="question" onClick={(e) => handleOpenPopover(e, 'question')}>
          <QuestionMarkOutlinedIcon />
        </StyledIconButton>
        <Popover
          id="question"
          open={openPopoverId === 'question'}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          PaperProps={{ sx: { width: 330 } }}
        >
          <Typography variant="body2">{t('certificate_action_b_11')}</Typography>
        </Popover>
      </BorderBlock>
    </StyledWrapper>
  );
};
