import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'app/store';

import { ConnectWitnessReq, RegisterWitnessReq } from './types';

export type WitnessState = {
  gods: { id: string; imageUrl: string; name: string }[];
};

export const initialState: WitnessState = {
  gods: [],
};

export const witnessSelector = {
  getGodWitnesses: (state: State): WitnessState['gods'] => state.witness.gods,
};

const witnessSlice = createSlice({
  name: 'witness',
  initialState,
  reducers: {
    updateWitnessState: (state, action: PayloadAction<Partial<WitnessState>>) => ({
      ...state,
      ...action.payload,
    }),

    clearWitnessState: () => ({
      ...initialState,
    }),

    /** For saga */
    getGodWitnesses(state, action: PayloadAction) {},
    connectWitness(state, action: PayloadAction<ConnectWitnessReq>) {},
    registerWitness(state, action: PayloadAction<RegisterWitnessReq>) {},
  },
});

export const { reducer: witnessReducer } = witnessSlice;
export const { actions: witnessActions } = witnessSlice;
